export function AccountStatementSvg() {
  return (
    <svg
      width='28'
      height='29'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0418 6.04199H6.7085V22.9587L8.57353 21.5081C9.01476 21.1649 9.63739 21.1829 10.0581 21.551L11.6668 22.9587L13.2319 21.5892C13.6718 21.2043 14.3286 21.2043 14.7684 21.5892L16.3335 22.9587L17.9423 21.551C18.3629 21.1829 18.9856 21.1649 19.4268 21.5081L21.2918 22.9587V12.4587M16.0418 6.04199L21.2918 12.4587M16.0418 6.04199V10.1253C16.0418 11.414 17.0865 12.4587 18.3752 12.4587H21.2918'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
