export function CartIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.75 3.75H15.25L13.6128 10.7081C13.4002 11.6115 12.5941 12.25 11.666 12.25H7.53947C6.63201 12.25 5.83827 11.639 5.60606 10.7618L3.75 3.75ZM3.75 3.75L3 0.75H0.75'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.5 15C6.5 15.2761 6.27614 15.5 6 15.5C5.72386 15.5 5.5 15.2761 5.5 15C5.5 14.7239 5.72386 14.5 6 14.5C6.27614 14.5 6.5 14.7239 6.5 15Z'
        stroke='white'
      />
      <path
        d='M13.5 15C13.5 15.2761 13.2761 15.5 13 15.5C12.7239 15.5 12.5 15.2761 12.5 15C12.5 14.7239 12.7239 14.5 13 14.5C13.2761 14.5 13.5 14.7239 13.5 15Z'
        stroke='white'
      />
    </svg>
  );
}
