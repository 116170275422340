import { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import {
  errorHandler,
  renderErrorToast,
  validateEmail,
} from "../utils/functions";
import { AuthWrapper } from "../components/auth-wrapper";

export function Login() {
  const navigate = useNavigate();
  const [email, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDoesUserExist = async () => {
    setLoading(true);
    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      setLoading(false);
      return renderErrorToast(
        "Invalid Email",
        "Email format is invalid",
        "invalid-email-address-err"
      );
    }
    try {
      await axios.get(
        `${
          process.env.REACT_APP_FIREBASE_URL
        }/shop/v4/getPhoneStatus?email=${email.toLowerCase()}`
      );
      return navigate("/confirm", {
        state: { contactEmail: email.toLowerCase(), action: "login" },
      });
    } catch (err) {
      setLoading(false);
      const { message, statusCode = null } = errorHandler(err);
      const errorMessage =
        statusCode === 404
          ? `Account not found. Please register your account`
          : message;
      renderErrorToast(
        "Oops!",
        errorMessage || "Something went wrong. Please contact support.",
        "invalid-user-err"
      );
    }
  };

  return (
    <AuthWrapper
      children={
        <div className='tdp_register'>
          <div className='form-container'>
            <div className='form-section'>
              <div className='form-itself'>
                <h3>Sign in to your account</h3>
                <input
                  className='form-control'
                  placeholder='Email address'
                  type='email'
                  onChange={({ target: { value } }) => setEmailAddress(value)}
                />
                <button
                  className=''
                  onClick={handleDoesUserExist}
                  disabled={!email || loading}
                >
                  {loading ? (
                    <div
                      class='spinner-border text-success app-spinner'
                      role='status'
                    >
                      <span class='sr-only'></span>
                    </div>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
