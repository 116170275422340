export function TDIcon() {
  return (
    <svg
      width='33'
      height='20'
      viewBox='0 0 33 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.0827 0.587524H0.0575111V7.19705H8.07832L0.0427246 14.6626L4.54273 19.5043L12.5668 12.0503V19.6292H19.1764V0.603954H19.0827V0.587524Z'
        fill='white'
      />
      <path
        d='M21 0.623703V19.6965C30.672 19.6965 32.9556 15.0963 32.9556 9.857C32.9556 4.61768 28.7415 0.623703 21 0.623703Z'
        fill='white'
      />
    </svg>
  );
}
