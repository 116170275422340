export function CheckSvg() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.95801 10.0002C3.95801 6.66344 6.66295 3.9585 9.99967 3.9585C13.3364 3.9585 16.0413 6.66344 16.0413 10.0002C16.0413 13.3369 13.3364 16.0418 9.99967 16.0418C6.66295 16.0418 3.95801 13.3369 3.95801 10.0002Z'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.125 10.625L8.48644 11.3953C8.77288 12.0058 9.62804 12.041 9.96365 11.4561L11.875 8.125'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
