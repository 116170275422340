export function TDCircleIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M24 47.2111C37.2548 47.2111 48 36.6425 48 23.6055C48 10.5686 37.2548 0 24 0C10.7452 0 0 10.5686 0 23.6055C0 36.6425 10.7452 47.2111 24 47.2111ZM26.5829 14.2073H7.55775V20.7082H15.5786L7.54297 28.051L12.043 32.8131L20.0671 25.4816V32.9359H26.6766V14.2234H26.5829V14.2073ZM28.5 33.0022V14.2428C36.2415 14.2428 40.4556 18.1712 40.4556 23.3244C40.4556 28.4776 38.172 33.0022 28.5 33.0022Z'
        fill='white'
      />
    </svg>
  );
}
