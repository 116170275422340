export function DeleteSvg() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.12312 6.41518C6.0992 6.14008 5.85679 5.93645 5.58169 5.96038C5.30658 5.9843 5.10296 6.22671 5.12688 6.50181L6.12312 6.41518ZM6.32596 14.5195L6.82408 14.4762L6.32596 14.5195ZM13.674 14.5195L13.1759 14.4762L13.674 14.5195ZM14.8731 6.50181C14.897 6.22671 14.6934 5.9843 14.4183 5.96038C14.1432 5.93645 13.9008 6.14008 13.8769 6.41518L14.8731 6.50181ZM5.12688 6.50181L5.82784 14.5629L6.82408 14.4762L6.12312 6.41518L5.12688 6.50181ZM7.98636 16.5418H12.0136V15.5418H7.98636V16.5418ZM14.1722 14.5629L14.8731 6.50181L13.8769 6.41518L13.1759 14.4762L14.1722 14.5629ZM12.0136 16.5418C13.1375 16.5418 14.0748 15.6825 14.1722 14.5629L13.1759 14.4762C13.1235 15.0791 12.6188 15.5418 12.0136 15.5418V16.5418ZM5.82784 14.5629C5.9252 15.6825 6.8625 16.5418 7.98636 16.5418V15.5418C7.3812 15.5418 6.87651 15.0791 6.82408 14.4762L5.82784 14.5629Z'
        fill='#6B797C'
      />
      <path
        d='M7.625 6.25016C7.625 6.52631 7.84886 6.75016 8.125 6.75016C8.40114 6.75016 8.625 6.52631 8.625 6.25016H7.625ZM11.375 6.25016C11.375 6.52631 11.5989 6.75016 11.875 6.75016C12.1511 6.75016 12.375 6.52631 12.375 6.25016H11.375ZM8.625 6.25016V5.62516H7.625V6.25016H8.625ZM9.79167 4.4585H10.2083V3.4585H9.79167V4.4585ZM11.375 5.62516V6.25016H12.375V5.62516H11.375ZM10.2083 4.4585C10.8527 4.4585 11.375 4.98083 11.375 5.62516H12.375C12.375 4.42855 11.405 3.4585 10.2083 3.4585V4.4585ZM8.625 5.62516C8.625 4.98083 9.14733 4.4585 9.79167 4.4585V3.4585C8.59505 3.4585 7.625 4.42855 7.625 5.62516H8.625Z'
        fill='#6B797C'
      />
      <path
        d='M4.16699 5.9585C3.89085 5.9585 3.66699 6.18235 3.66699 6.4585C3.66699 6.73464 3.89085 6.9585 4.16699 6.9585V5.9585ZM15.8337 6.9585C16.1098 6.9585 16.3337 6.73464 16.3337 6.4585C16.3337 6.18235 16.1098 5.9585 15.8337 5.9585V6.9585ZM4.16699 6.9585H15.8337V5.9585H4.16699V6.9585Z'
        fill='#6B797C'
      />
    </svg>
  );
}

export function DeleteSvg2() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.79199 6.4585L5.49295 14.5195C5.56785 15.3808 6.28884 16.0418 7.15335 16.0418H12.014C12.8785 16.0418 13.5995 15.3808 13.6744 14.5195L14.3753 6.4585H4.79199Z'
        stroke='#E61010'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.125 8.9585V13.5418'
        stroke='#E61010'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.042 8.9585V13.5418'
        stroke='#E61010'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.29199 6.4585V5.62516C7.29199 4.70469 8.03818 3.9585 8.95866 3.9585H10.2087C11.1291 3.9585 11.8753 4.70469 11.8753 5.62516V6.4585'
        stroke='#E61010'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.95801 6.4585H15.208'
        stroke='#E61010'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
