import { Loader } from "../loader";

export function DeleteModal({ loading = false, deleteAction = null }) {
  return (
    <div
      class='modal fade delete-modal'
      id='deleteModal'
      tabindex='-1'
      aria-labelledby='deleteModalLabel'
      aria-hidden='true'
    >
      <div class='modal-dialog'>
        <div class='modal-content'>
          <div class='modal-header'>
            {/* <button
              type='button'
              class='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button> */}
            <span
              data-bs-dismiss='modal'
              aria-label='Close'
              className='close-btn'
            >
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.375 5.625L5.625 14.375'
                  stroke='#081F24'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M5.625 5.625L14.375 14.375'
                  stroke='#081F24'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </span>
            <h1 class='modal-title fs-5' id='deleteModalLabel'>
              Delete order
            </h1>
          </div>
          <div class='modal-body'>
            <div class='delete-icon-container'>
              <svg
                width='37'
                height='37'
                viewBox='0 0 37 37'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10.625 12.4824L11.8867 26.9923C12.0215 28.5426 13.3193 29.7324 14.8755 29.7324H22.1245C23.6807 29.7324 24.9785 28.5426 25.1133 26.9923L26.375 12.4824'
                  stroke='#081F24'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M15.125 12.1074V10.9824C15.125 9.32557 16.4681 7.98242 18.125 7.98242H18.875C20.5319 7.98242 21.875 9.32557 21.875 10.9824V12.1074'
                  stroke='#081F24'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M8 12.4824H29'
                  stroke='#141414'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
            <h3>Are you sure?</h3>
            <h6>You will be unable to undo this action once confirmed</h6>
          </div>
          <div class='modal-footer'>
            <button
              disabled={loading}
              type='button'
              onClick={() => {
                deleteAction();
                document.getElementById("close-button").click();
              }}
            >
              {loading ? <Loader /> : "Confirm Deletion"}
            </button>
            <button
              disabled={loading}
              type='button'
              id='close-button'
              class='secondary'
              data-bs-dismiss='modal'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
