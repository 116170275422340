import {
  errorHandler,
  LSUserData,
  renderErrorToast,
  updateLSOutlet,
} from "./functions";
import axios from "axios";

const refreshOutlet = async () => {
  const { retailOutlets: [retailOutlet] = [], apiKey } = LSUserData() || {};
  try {
    const {
      data: { data },
    } = await axios.get(
      `${process.env.REACT_APP_TD_APP_URL}/api/v4/procurement/get-retail-outlet?outletId=${retailOutlet?._id}`,
      { headers: { "x-api-key": apiKey } }
    );
    updateLSOutlet(data);
  } catch (error) {
    const { message } = errorHandler(error);
    renderErrorToast(
      "Error refreshing outlet!",
      message,
      "error-updating-outlet-hook"
    );
  }
};

export default refreshOutlet;
