export function ShipmentIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.7502 19.25H7.25C6.14543 19.25 5.25 18.3546 5.25 17.25V9.63478C5.25 9.22174 5.37788 8.81884 5.61606 8.48141L8.25 4.75H16.7502L19.3842 8.48141C19.6224 8.81884 19.7502 9.22174 19.7502 9.63478V17.25C19.7502 18.3546 18.8548 19.25 17.7502 19.25Z'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.5 9.25H19.5'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5 5V9'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
