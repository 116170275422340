export function LegalIcon() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.12467 4.29159H8.04134M5.12467 6.79159H8.04134M11.6872 0.958252C10.9393 0.958252 10.5413 1.79772 10.5413 2.83325V3.04159M11.6872 0.958252C12.4351 0.958252 13.0413 1.79772 13.0413 2.83325V3.04159H10.5413M11.6872 0.958252H4.29134C3.37087 0.958252 2.62467 1.70444 2.62467 2.62492V10.9583M10.5413 3.04159V11.1666C10.5413 12.2021 9.72673 13.0416 8.97884 13.0416M8.97884 13.0416C8.23096 13.0416 7.62467 12.2021 7.62467 11.1666V10.9583H2.62467M8.97884 13.0416H2.62467C1.7042 13.0416 0.958008 12.2954 0.958008 11.3749V10.9583H2.62467'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
