export function CreditNoteIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.375 18C21.375 19.864 19.864 21.375 18 21.375C16.136 21.375 14.625 19.864 14.625 18C14.625 16.136 16.136 14.625 18 14.625C19.864 14.625 21.375 16.136 21.375 18Z'
        stroke='#081F24'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18 11.3089C12 14.1675 7.125 11.6666 7.125 11.6666V24.375C7.125 24.375 12 27.2097 18 24.8871C24 22.5645 28.875 24.375 28.875 24.375V11.6666C28.875 11.6666 24 8.45039 18 11.3089Z'
        stroke='#081F24'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
