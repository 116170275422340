export function DownloadIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.9585 12.292V13.542C3.9585 14.9227 5.07778 16.042 6.4585 16.042H13.5418C14.9225 16.042 16.0418 14.9227 16.0418 13.542V12.292'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10 11.8747L10 3.95801'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.2915 8.95801L9.99984 11.8747L12.7082 8.95801'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
