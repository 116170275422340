import DatePicker from "react-datepicker";
import { CalendarIcon } from "../assets/calendarsvg";

export function CustomCalendarDatePicker({
  selectedDates = [],
  setSelectedDates = null,
}) {
  return (
    <div className='date-picker-container'>
      <DatePicker
        monthsShown={2}
        onChange={(dates) => setSelectedDates(dates)}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        selectsRange
        dateFormat='MMM d'
        showIcon
        toggleCalendarOnIconClick
        closeOnScroll
        icon={<CalendarIcon />}
      />
    </div>
  );
}
