/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { CustomChevronLeftIcon } from "../assets/custom-chevron-left";
import { TDCircleIcon } from "../assets/td.icon.circle";

export function AuthWrapper({ children, page, onGoBack, userIsOnLoginScreen }) {
  const navigate = useNavigate();
  const isLoginScreen =
    window.location.pathname === "/login" || userIsOnLoginScreen;

  return (
    <div className='tdp_auth_wrapper'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-4 _left'>
            <TDCircleIcon />
            <h3>Iconic brands for the next billion consumers</h3>
            <h6>
              Join our global network of importers and distribution partners,
              offering premium products for every market.
            </h6>
          </div>
          <div className='col-8 _right'>
            <div className='navigation'>
              <div className='_back' onClick={onGoBack}>
                {page && page < 3 ? (
                  <>
                    <CustomChevronLeftIcon />
                    <a>Back</a>
                  </>
                ) : null}
              </div>
              {isLoginScreen ? (
                <div className='login-section'>
                  <p>Don't have an account?</p>
                  <button onClick={() => navigate("/register")}>
                    Register
                  </button>
                </div>
              ) : (
                <div className='login-section'>
                  <p>Already have an account?</p>
                  <button onClick={() => navigate("/login")}>Login</button>
                </div>
              )}
            </div>
            <div className={`register-form ${!page ? "options" : ""}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
