/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment/moment";
import { ChevronRight } from "../../assets/chevrons";
import { useEffect, useMemo, useState } from "react";
import { CalendarIcon } from "../../assets/calendarsvg";
import { DownloadIcon } from "../../assets/download.icon";
import { ImageWithFallback } from "../../pages/console";
import axios from "axios";
import {
  errorHandler,
  handleGlobalDownloadInvoice,
  LSUserData,
  renderErrorToast,
} from "../../utils/functions";
import Skeleton from "react-loading-skeleton";
import { InvoiceView } from "../invoice-view";
import { Loader } from "../loader";

export function ShipmentViewModal({ shipment, setShipment }) {
  const [viewType, setViewType] = useState("information");
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { apiKey } = LSUserData() || {};

  const trackingStatuses = useMemo(() => {
    if (!shipmentDetails) return [];
    const { history, shippingStatus } = shipmentDetails;
    if (!history) return;
    const statuses = [
      "Open",
      "Processing",
      "Ready",
      "Scheduled",
      "Dispatched",
      "Skipped",
      "Delivered",
    ].map((el) => ({ status: el, completed: false, pending: false }));
    const status = shippingStatus?.toLowerCase();
    statuses[0] = {
      ...statuses[0],
      completed: true,
      pending: false,
      createdAt: shipmentDetails?.createdAt,
    };
    const processingEvent = history.find(
      (event) => event?.newValue?.toLowerCase() === "processing"
    );
    if (processingEvent) {
      statuses[1] = {
        ...statuses[1],
        completed: true,
        createdAt: processingEvent?.createdAt,
      };
      if (status === "processing") statuses[2].pending = true;
    }
    const readyEvent = history.find(
      (event) => event?.newValue?.toLowerCase() === "ready"
    );
    if (readyEvent) {
      statuses[2] = {
        ...statuses[2],
        completed: true,
        createdAt: readyEvent?.createdAt,
      };
      if (status === "ready") statuses[3].pending = true;
    }

    const scheduledEvent = history.find(
      (event) => event?.newValue?.toLowerCase() === "scheduled"
    );
    if (scheduledEvent) {
      statuses[3] = {
        ...statuses[3],
        completed: true,
        createdAt: scheduledEvent?.createdAt,
      };
      if (status === "scheduled") statuses[4].pending = true;
    }

    const dispatchedEvent = history.find(
      (event) => event?.newValue?.toLowerCase() === "dispatched"
    );
    if (dispatchedEvent) {
      statuses[4] = {
        ...statuses[4],
        completed: true,
        createdAt: dispatchedEvent?.createdAt,
      };
      if (status === "dispatched") statuses[5].pending = true;
    }

    const skippedEvent = history.find(
      (event) => event?.newValue?.toLowerCase() === "skipped"
    );
    if (skippedEvent) {
      statuses[5] = {
        ...statuses[5],
        completed: true,
        createdAt: skippedEvent?.createdAt,
      };
      if (status === "skipped") statuses[6].pending = true;
    }

    const deliveredEvent = history.find(
      (event) => event?.newValue?.toLowerCase() === "delivered"
    );
    if (deliveredEvent) {
      statuses[6] = {
        ...statuses[6],
        completed: true,
        createdAt: deliveredEvent?.createdAt,
      };
    }
    return statuses.reverse();
  }, [shipmentDetails]);

  useEffect(() => {
    setViewType("information");
    setShipmentDetails(null);
    if (!shipment?._id) return;
    handleFetchShipmentDetails(shipment._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  useEffect(() => {
    const modalElement = document.getElementById("shipmentOffCanvasExample");
    if (!modalElement) return;

    const handleHiddenEvent = () => {
      setShipment(null);
      setShipmentDetails(null);
      setLoading(true);
    };

    // Attach event listener
    modalElement.addEventListener(
      "hidden.bs.shipment_details",
      handleHiddenEvent
    );

    // Cleanup the event listener
    return () => {
      modalElement.removeEventListener(
        "hidden.bs.shipment_details",
        handleHiddenEvent
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchShipmentDetails = async (shipmentId) => {
    try {
      setLoading(true);
      const shipmentDetailsUrl = `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/ordershipments/${shipmentId}`;
      const documentsUrl = `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/ordershipments/${shipmentId}/documents`;
      const [
        {
          data: { data: details },
        },
        { data: documents },
      ] = await Promise.all([
        await axios.get(shipmentDetailsUrl, {
          headers: { "x-api-key": apiKey },
        }),
        await axios.get(documentsUrl, { headers: { "x-api-key": apiKey } }),
      ]);
      setShipmentDetails({ ...details, documents });
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <div
      className='offcanvas offcanvas-end tdp_offcanvas shipment_details'
      tabindex='-1'
      id='shipmentOffCanvasExample'
      aria-labelledby='shipmentOffCanvasExampleLabel'
    >
      <div className='offcanvas-header'>
        <h5 className='offcanvas-title' id='shipmentOffCanvasExampleLabel'>
          <i
            className='bi bi-x-lg'
            data-bs-dismiss='shipment_details'
            aria-label='Close'
            id='close'
            data-bs-toggle='offcanvas'
            href='#shipmentOffCanvasExample'
            role='button'
            aria-controls='shipmentOffCanvasExample'
          ></i>
          Shipments
          <p>
            <ChevronRight /> Container Information
          </p>
        </h5>
      </div>
      <div className='body-container'>
        <div className='offcanvas-body'>
          <div className='heading'>
            <h5>{shipmentDetails?.shipmentNumber}</h5>
            <div className='info'>
              <div>
                <p className='title'>Ship To</p>
                <p className='value'>
                  {shipmentDetails?.shippingAddress?.address1 || "-"}
                </p>
              </div>
              <div>
                <p className='title'>Bill of Lading</p>
                <p className='value'>
                  {shipmentDetails?.shippingDetails?.billOfLading || "-"}
                </p>
              </div>
              <div>
                <p className='title'>Status</p>
                <p className='value'>
                  {shipmentDetails?.shippingStatus || "-"}
                </p>
              </div>
              <div>
                <p className='title'>Carrier</p>
                <p className='value'>
                  {shipmentDetails?.shippingDetails?.carrierName || "-"}
                </p>
              </div>
              <div>
                <p className='title'>Tracking url</p>
                <a
                  className='value link'
                  href={shipmentDetails?.trackingUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {shipmentDetails?.trackingUrl || "-"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='offcanvas-body info-container'>
          <div className='information-view'>
            <div className='filter-buttons'>
              <button
                className={viewType === "information" ? "active" : ""}
                onClick={() => setViewType("information")}
              >
                Information
              </button>
              <button
                className={viewType === "documents" ? "active" : ""}
                onClick={() => setViewType("documents")}
              >
                Documents
              </button>
              <button
                className={viewType === "items" ? "active" : ""}
                onClick={() => setViewType("items")}
              >
                Items
              </button>
            </div>
            {viewType === "information" ? (
              <Information shipment={shipmentDetails} loading={loading} />
            ) : viewType === "documents" ? (
              <Documents shipment={shipmentDetails} pageLoading={loading} />
            ) : viewType === "items" ? (
              <Items shipment={shipmentDetails} pageLoading={loading} />
            ) : null}
          </div>
          <div className='_tracking'>
            <div className='container'>
              <h2>Tracking & Alerts</h2>
              <div className='tracking-steps'>
                {(trackingStatuses ?? []).map((tracking, _id) => (
                  <div className='step' key={tracking?.status}>
                    <div>
                      <h5>
                        <span
                          className={`status-dot ${
                            tracking?.completed
                              ? "done"
                              : tracking?.pending
                              ? "pending"
                              : "open"
                          }`}
                        />
                        {tracking?.status}
                      </h5>
                      <div className='logistics'>
                        {tracking?.createdAt ? (
                          <div className='time'>
                            <CalendarIcon />
                            <span>
                              {moment
                                .utc(tracking?.createdAt)
                                .format("DD/MM/YYYY HH:mm A")}
                            </span>
                          </div>
                        ) : null}
                        {/* <div className='location'>
                          <LocationIcon2 />
                          <span>Los Angeles, USA</span>
                        </div> */}
                      </div>
                      {tracking?.completed ? (
                        <p className='comment'>
                          Shipment {tracking?.status?.toLowerCase()}
                          {_id
                            ? ` and confirmed for ${trackingStatuses?.[
                                _id - 1
                              ]?.status?.toLowerCase()}`
                            : null}
                        </p>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Information({ shipment, loading }) {
  const [selectedInvoice, setInvoice] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const { apiKey } = LSUserData() || {};

  const handleDownloadInvoice = async (invoice) => {
    setInvoice(invoice);
    await handleGlobalDownloadInvoice(invoice, setDownloading, apiKey);
  };

  return (
    <div className='info-box'>
      <div className='info-body'>
        <div className='details-container'>
          <h3>Details</h3>
          <div className='details'>
            <div className='__col'>
              <p>Customer Name</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>{shipment?.shippingAddress?.company || "-"}</p>
              )}
            </div>
            <div className='__col'>
              <p>Customer Address</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>{shipment?.shippingAddress?.address1 || "-"}</p>
              )}
            </div>
            <div className='__col'>
              <p>Shipment Number</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>{shipment?.shipmentNumber || "-"}</p>
              )}
            </div>
            <div className='__col'>
              <p>Created Date</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>{moment(shipment?.createdAt).format("DD/MM/YYYY")}</p>
              )}
            </div>
            <div className='__col'>
              <p>Bill of Lading </p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>{shipment?.shippingDetails?.billOfLading || "-"}</p>
              )}
            </div>
            <div className='__col'>
              <p>Documents Status</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>
                  {shipment?.documents?.length ?? 0} available
                  {/* <span>&bull;</span>{" "}
                0 pending */}
                </p>
              )}
            </div>
            <div className='__col'>
              <p>Shipping Status</p>
              {loading ? <Skeleton /> : <p>{shipment?.shippingStatus}</p>}
            </div>
            <div className='__col'>
              <p>Documents Available</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>
                  {!shipment?.documents?.length
                    ? "-"
                    : shipment?.documents
                        .map((el) => el.documentType)
                        .join(", ")}
                </p>
              )}
            </div>
            <div className='__col'>
              <p>Carrier</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>{shipment?.shippingDetails?.carrierName || "-"}</p>
              )}
            </div>
            <div className='__col'>
              <p>Ship At</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>
                  {shipment?.shippingDetails?.shipAt
                    ? moment(shipment?.shippingDetails?.shipAt).format(
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </p>
              )}
            </div>
            <div className='__col'>
              <p>Order Numbers</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p>
                  {shipment?.orders?.length
                    ? shipment?.orders?.map((el) => el.orderNumber).join(", ")
                    : "-"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='info-body'>
        <div className='containers'>
          <h3>Containers</h3>
          <table className='table'>
            <thead>
              <tr>
                <th>Container number</th>
                <th>Container name</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? [1, 2].map((_) => <Skeleton />)
                : shipment?.shippingDetails?.containers?.map(
                    (container, _id) => (
                      <tr key={_id}>
                        <td>{container?.containerNumber}</td>
                        <td>{container?.name}</td>
                        <td>{container?.details}</td>
                      </tr>
                    )
                  )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='info-body'>
        <div className='containers invoices'>
          <h3>Invoices</h3>
          <table className='table'>
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? [1, 2].map((_) => <Skeleton />)
                : shipment?.invoice?.map((invoice, id) => (
                    <tr key={id}>
                      <td>
                        <a
                          onClick={() => {
                            const invoiceModalInstance =
                              document.getElementById("offcanvasInvoiceView");
                            const invoiceModal = new window.bootstrap.Offcanvas(
                              invoiceModalInstance
                            );
                            invoiceModal.show();
                            setInvoice(invoice);
                          }}
                        >
                          #{invoice?.invoiceNumber}
                        </a>
                      </td>
                      <td>{moment(invoice?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <button
                          disabled={downloading}
                          onClick={() => handleDownloadInvoice(invoice)}
                        >
                          {selectedInvoice?._id === invoice?._id &&
                          downloading ? (
                            <Loader />
                          ) : (
                            "Download"
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <InvoiceView
        invoice={selectedInvoice}
        downloading={downloading}
        setDownloading={setDownloading}
        handleDownloadInvoice={(invoice) =>
          handleGlobalDownloadInvoice(invoice, setDownloading, apiKey)
        }
      />
    </div>
  );
}

function Documents({ shipment, pageLoading }) {
  const [loading, setLoading] = useState(false);

  const handleDownloadDocument = async (documentId) => {
    if (loading) return;
    try {
      setLoading(true);
      window.open(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/shipment/${documentId}/download`,
        "_blank"
      );
      setLoading(false);
    } catch (error) {
      const {
        message = "Unable to download this document at this time. Please try again later",
      } = errorHandler(error);
      setLoading(false);
      return renderErrorToast(
        "Document Download Error!",
        message,
        "err-downloading-documents"
      );
    }
  };

  return (
    <div className='info-box'>
      <div className='info-body'>
        <div className='containers documents'>
          <h3>Documents</h3>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pageLoading
                ? [1, 2].map((_) => <Skeleton />)
                : (shipment?.documents || [])?.map((document, _id) => (
                    <tr key={_id}>
                      <td>
                        {document?.documentType}.
                        {document?.extension?.toLowerCase()}
                      </td>
                      <td>
                        <div
                          className='download'
                          disabled={loading}
                          onClick={() => handleDownloadDocument(document?._id)}
                        >
                          {loading ? <Loader /> : <DownloadIcon />}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function Items({ shipment, pageLoading }) {
  return (
    <div className='info-box'>
      <div className='info-body'>
        <div className='containers items'>
          <h3>Items</h3>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {pageLoading
                ? [1, 2].map((_) => <Skeleton />)
                : (shipment?.items || []).map((item, _id) => (
                    <tr key={_id}>
                      <td>
                        <div className=''>
                          <ImageWithFallback
                            src={`https://pimg.tradedepot.co/xs/${item?.variantId}.png`}
                            isListScreen={true}
                            key={item?.variantId}
                          />
                          <p>{item?.name}</p>
                        </div>
                      </td>
                      <td>{item?.quantity}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
