export function LegalComplianceIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.875 13.875L19.125 7.125M25.875 13.875H20.625C19.7966 13.875 19.125 13.2034 19.125 12.375V7.125M25.875 13.875V15.375M19.125 7.125H10.125C8.46815 7.125 7.125 8.46815 7.125 10.125V25.875C7.125 27.5319 8.46815 28.875 10.125 28.875H15.375M20.625 22.275L24.75 20.625L28.875 22.275C28.875 22.275 28.875 28.875 24.75 28.875C20.625 28.875 20.625 22.275 20.625 22.275Z'
        stroke='#081F24'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
