export function Orders() {
  return (
    <svg
      width='28'
      height='29'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.54175 9.83333L14.0001 6.04166L22.4584 9.83333L14.0001 13.625L5.54175 9.83333Z'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.54175 19.1667L14.0001 22.9583L22.4584 19.1667'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.4583 9.83334V19.1667'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.54175 9.83334V19.1667'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14 13.9167V22.6667'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export function SalesOrders() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.25 8.75H5.75C5.19772 8.75 4.75 9.19772 4.75 9.75V15.25C4.75 15.8023 5.19772 16.25 5.75 16.25H6.25C6.80228 16.25 7.25 15.8023 7.25 15.25V9.75C7.25 9.19772 6.80228 8.75 6.25 8.75Z'
        stroke='#141414'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.25 4.75H11.75C11.1977 4.75 10.75 5.19772 10.75 5.75V15.25C10.75 15.8023 11.1977 16.25 11.75 16.25H12.25C12.8023 16.25 13.25 15.8023 13.25 15.25V5.75C13.25 5.19772 12.8023 4.75 12.25 4.75Z'
        stroke='#141414'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.75 19.25H19.25'
        stroke='#141414'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18.25 8.75H17.75C17.1977 8.75 16.75 9.19772 16.75 9.75V15.25C16.75 15.8023 17.1977 16.25 17.75 16.25H18.25C18.8023 16.25 19.25 15.8023 19.25 15.25V9.75C19.25 9.19772 18.8023 8.75 18.25 8.75Z'
        stroke='#141414'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
