export function PowerPlant() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.2504 11.8654L13.5004 8.75V11.8654L19.7504 8.75V19.25H8.00042M13.2504 19.25V15.75C13.2504 15.1977 13.6981 14.75 14.2504 14.75H15.7504C16.3027 14.75 16.7504 15.1977 16.7504 15.75V19.25M6.35042 4.75H9.65042L10.7504 19.25H6.32916C5.74707 19.25 5.28799 18.7548 5.33202 18.1744L6.35042 4.75Z'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
