import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/styles/fonts.css";
import Router from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css"; // skeleton
import "react-datepicker/dist/react-datepicker.css"; // date picker
import "react-multi-carousel/lib/styles.css"; // carousel
import "react-tooltip/dist/react-tooltip.css"; // react tooltip
import "animate.css"; // animation
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://cdeb11f5404359c3df0625e705a0beaf@o247183.ingest.us.sentry.io/4508262256672768",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router />
    <ToastContainer
      autoClose={7000}
      hideProgressBar={true}
      closeButton={false}
      position='bottom-right'
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
