export function UploadIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.125 17.625V25.875C7.125 27.5319 8.46815 28.875 10.125 28.875H25.875C27.5319 28.875 28.875 27.5319 28.875 25.875V17.625M18 13.875V7.125M18 7.125L14.625 10.875M18 7.125L21.375 10.875M21.375 23.625H14.625C13.7966 23.625 13.125 22.9534 13.125 22.125V19.875C13.125 19.0466 13.7966 18.375 14.625 18.375H21.375C22.2034 18.375 22.875 19.0466 22.875 19.875V22.125C22.875 22.9534 22.2034 23.625 21.375 23.625Z'
        stroke='#081F24'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
