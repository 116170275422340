import { useEffect, useMemo, useState } from "react";
import {
  errorHandler,
  formatMoney,
  renderErrorToast,
} from "../../utils/functions";
import { PRODUCT_PREPARE_ERRORS } from "../../utils/constants";
import { Loader } from "../loader";

export function ReviewOrderDrawer({
  reviewOrderData,
  productsInReview,
  editingDraft,
  selectedProducts,
  preparedOrders,
  totalCalculation,
  isDraft,
  prepareOrder,
  handleChangeProcessingProductValue,
  handleCreateDraftOrder,
  handleCreateOrder: handleOrderCreate,
  creatingDraft,
  creatingOrder,
  setCreatingDraft,
  setCreatingOrder,
  productValueChanged,
  setProductValueChanged,
}) {
  const [screenPage, setScreenPage] = useState(1);
  const [loading, setLoading] = useState(creatingDraft || creatingOrder);

  useEffect(() => {
    setScreenPage(productsInReview.length ? 1 : 2);
    if (loading) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInReview]);

  const fulfillableItems = useMemo(() => {
    const orders =
      preparedOrders?.orders?.filter((el) => el.metadata.fulfillable) || [];
    const items = orders.reduce(
      (acc, curr) =>
        [...acc, ...curr.items].map((el) => ({
          ...el,
          count: el.quantity,
          currency: curr.currency,
        })),
      []
    );
    return items;
  }, [preparedOrders]);

  const unfulfillableItems = useMemo(() => {
    const orders =
      preparedOrders?.orders?.filter((el) => !el.metadata.fulfillable) || [];
    const items = orders
      .reduce((acc, curr) => {
        const items = curr.items.map((el) => ({
          ...el,
          count: el.quantity,
          currency: curr.currency,
          errormessage: curr.metadata.errorMessage,
          errorTag: curr.metadata.error,
        }));
        return [...acc, ...items];
      }, [])
      .map((el) => {
        return {
          ...selectedProducts.find(
            (product) => product.variantId === el.variantId
          ),
          errorMessage: el.errormessage,
          errorTag: el.errorTag,
        };
      });
    return items;
  }, [preparedOrders, selectedProducts]);

  const groupedUnfulfilledItems = unfulfillableItems.reduce((acc, curr) => {
    const groupKey = curr["errorTag"];
    if (!acc[groupKey]) acc[groupKey] = [];
    acc[groupKey].push(curr);
    return acc;
  }, {});

  const handleCreateOrder = async ({ isDraft = false }) => {
    if (!fulfillableItems.length)
      return renderErrorToast(
        "Create Order Error",
        "There are no items to create as an order",
        "create-order-err-1"
      );
    try {
      if (isDraft) await handleCreateDraftOrder();
      else handleOrderCreate();
    } catch (error) {
      const { message } = errorHandler(error);
      setCreatingOrder(false);
      setCreatingDraft(false);
      return renderErrorToast(
        "Create Order Error",
        message,
        "create-order-err-1"
      );
    }
  };

  const prepareOrders = async () => {
    if (!fulfillableItems.length && !productValueChanged)
      return renderErrorToast(
        "Create Order Error",
        "There are no items to create as an order",
        "create-order-err-1"
      );
    setLoading(true);
    await prepareOrder({ isCreatingDraft: isDraft });
    if (fulfillableItems.length) setScreenPage(2);
    else setProductValueChanged(false);
    setLoading(false);
  };

  const cancelOrderProcess = () => {
    document.getElementById("close-review-order-drawer").click();
    setScreenPage(1);
  };

  const handleProductValueChange = (product, value) => {
    if (!productValueChanged) setProductValueChanged(true);
    return handleChangeProcessingProductValue(
      product,
      Number(value),
      true // autorefresh
    );
  };

  return (
    <>
      <p
        style={{ display: "none" }}
        data-bs-toggle='offcanvas'
        href='#offcanvasrevieworder'
        role='button'
        aria-controls='offcanvasrevieworder'
        id='offcanvas-revieworder'
      ></p>
      <div
        className='offcanvas offcanvas-end tdp_phone_offcanvas  tdp_review_order_offcanvas'
        tabindex='-1'
        id='offcanvasrevieworder'
        aria-labelledby='offcanvasrevieworderLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvas-reviewOrderLabel'>
            <i
              className='bi bi-x-lg'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='close-review-order-drawer'
            ></i>
            <p>Confirm Order</p>
          </h5>
        </div>
        <div className='offcanvas-body tdp_product_review'>
          {screenPage === 1 ? (
            <div className='body'>
              {Object.keys(groupedUnfulfilledItems).map((el, key) => {
                const items = groupedUnfulfilledItems[el];
                const { title, message, type } = PRODUCT_PREPARE_ERRORS.find(
                  (error) => error.tag === el
                );
                return (
                  <div className='error_block' key={key}>
                    <h3>{title}</h3>
                    <h5>{message}</h5>
                    <div className='error-container'>
                      <div className='error-inputs-flex'>
                        {items.map((item, key) => (
                          <div className='input-flex' key={key}>
                            <input value={item.name} />
                            <input
                              disabled={type === "oos"}
                              value={Number(item?.count).toString()}
                              type='number'
                              min={1}
                              onChange={({ target: { value } }) =>
                                handleProductValueChange(item, value)
                              }
                            />
                            <input disabled value={item.count * item.price} />
                          </div>
                        ))}
                      </div>
                      {productValueChanged ? null : (
                        <p className='error-message'>{title}</p>
                      )}
                    </div>
                  </div>
                );
              })}
              {fulfillableItems.length ? (
                <div className='error_block'>
                  <h3>Available Items</h3>
                  <h5>The following items are available for purchase</h5>
                  <div className='error-container'>
                    <div className='error-inputs-flex'>
                      {fulfillableItems.map((item, key) => (
                        <div className='input-flex' key={key}>
                          <input value={item.name} />
                          <input disabled type='number' value={item.count} />
                          <input
                            disabled
                            value={`${item.currency.symbol}${formatMoney(
                              item.price * item.count
                            )}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              <button
                onClick={() => prepareOrders()}
                disabled={
                  loading ||
                  (!(fulfillableItems || []).length && !productValueChanged)
                }
              >
                {loading ? <Loader /> : "Proceed to create order"}
              </button>
              <button
                className='secondary'
                disabled={loading}
                onClick={cancelOrderProcess}
              >
                Cancel order creation
              </button>
            </div>
          ) : screenPage === 2 ? (
            <div className='body'>
              <div className='error_block'>
                <h3>Confirm order details</h3>
                <h5>Review and reconfirm your order details</h5>
                <div className='confirm-items'>
                  <h4>Order details</h4>
                  {(fulfillableItems || [])?.map((item, key) => (
                    <div className='item' key={key}>
                      <div className=''>
                        <p>{item?.name}</p>
                        <span>x{item?.count}</span>
                      </div>
                      <p>
                        {item?.currency?.symbol}
                        {formatMoney(item?.price * item?.count)}
                      </p>
                    </div>
                  ))}
                  <div className='cummulatives'>
                    <div className='total'>
                      <p>Subtotal</p>
                      <p>
                        {fulfillableItems?.[0]?.currency?.symbol}
                        {formatMoney(totalCalculation.subtotal || 0)}
                      </p>
                    </div>
                    <div className='total'>
                      <p>Discount</p>
                      <p>
                        {fulfillableItems?.[0]?.currency?.symbol}
                        {formatMoney(totalCalculation.discount || 0)}
                      </p>
                    </div>
                    <div className='total'>
                      <p>Taxes</p>
                      <p>
                        {fulfillableItems?.[0]?.currency?.symbol}
                        {formatMoney(totalCalculation.taxes || 0)}
                      </p>
                    </div>
                    <div className='total'>
                      <p>Amount due</p>
                      <p>
                        {fulfillableItems?.[0]?.currency?.symbol}
                        {formatMoney(totalCalculation.amountDue || 0)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                disabled={
                  creatingDraft ||
                  creatingOrder ||
                  !(fulfillableItems || []).length
                }
                onClick={() => handleCreateOrder({ isDraft })}
              >
                {creatingDraft || creatingOrder ? (
                  <Loader />
                ) : isDraft ? (
                  editingDraft ? (
                    "Update Draft"
                  ) : (
                    "Create Draft"
                  )
                ) : (
                  "Create Order"
                )}
              </button>
              <button
                className='secondary'
                disabled={creatingDraft || creatingOrder}
                onClick={() => setScreenPage(1)}
              >
                Cancel
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
