export function ShoppingCart() {
  return (
    <svg
      width='28'
      height='29'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.04175 9.54167H22.4584L20.5484 17.6594C20.3004 18.7134 19.3598 19.4583 18.277 19.4583H13.4628C12.4041 19.4583 11.4781 18.7455 11.2072 17.7221L9.04175 9.54167ZM9.04175 9.54167L8.16675 6.04167H5.54175'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.2499 22.6667C12.2499 22.9888 11.9888 23.25 11.6666 23.25C11.3444 23.25 11.0833 22.9888 11.0833 22.6667C11.0833 22.3445 11.3444 22.0833 11.6666 22.0833C11.9888 22.0833 12.2499 22.3445 12.2499 22.6667Z'
        stroke='#6B797C'
      />
      <path
        d='M20.4167 22.6667C20.4167 22.9888 20.1555 23.25 19.8333 23.25C19.5112 23.25 19.25 22.9888 19.25 22.6667C19.25 22.3445 19.5112 22.0833 19.8333 22.0833C20.1555 22.0833 20.4167 22.3445 20.4167 22.6667Z'
        stroke='#6B797C'
      />
    </svg>
  );
}
