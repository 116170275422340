export function ShipmentMenuIcon() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.54199 20.7083C5.54199 20.7083 8.75033 24.4179 14.0003 21C19.2503 17.582 22.4587 21.2917 22.4587 21.2917'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20.708 16.6249L22.1663 13.7083H5.83301L7.7653 17.7916'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.292 13.4167V5.54175L8.16699 13.4167'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
