export function InquirySvg() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.27273 0C0.569545 0 0 0.569545 0 1.27273V12.7273C0 13.4305 0.569545 14 1.27273 14H2.26332C2.04441 13.6239 1.90909 13.1931 1.90909 12.7273V1.27273C1.90909 0.806909 2.04441 0.376091 2.26332 0H1.27273ZM4.45455 0C3.75136 0 3.18182 0.569545 3.18182 1.27273V12.7273C3.18182 13.4305 3.75136 14 4.45455 14H12.7273C13.4305 14 14 13.4305 14 12.7273V1.27273C14 0.569545 13.4305 0 12.7273 0H4.45455ZM8.59091 2.54545C9.99473 2.54545 11.1364 3.68709 11.1364 5.09091C11.1364 6.18227 10.455 6.81164 9.90714 7.31818C9.48586 7.707 9.22727 7.96218 9.22727 8.27273H7.95455C7.95455 7.38882 8.55841 6.83092 9.04332 6.38228C9.55178 5.91264 9.86364 5.59936 9.86364 5.09091C9.86364 4.389 9.29282 3.81818 8.59091 3.81818C7.889 3.81818 7.31818 4.389 7.31818 5.09091H6.04545C6.04545 3.68709 7.18709 2.54545 8.59091 2.54545ZM8.59339 9.9581C9.18776 9.9581 9.54545 10.2772 9.54545 10.8232C9.54545 11.3596 9.18776 11.6783 8.59339 11.6783C7.99394 11.6783 7.63636 11.3596 7.63636 10.8232C7.63636 10.2772 7.99394 9.9581 8.59339 9.9581Z'
        fill='#6B797C'
      />
    </svg>
  );
}
