export function NotFoundView() {
  return (
    <div className='tdp_not_found_page'>
      <div className='container'>
        <div className='row flex'>
          <div className='col-7'>
            <img src='images/404.png' alt='404' className='img-fluid' />
          </div>
          <div className='col-5'>
            <h1>Oops!</h1>
            <p>We couldn't find the page you're looking for</p>
            <a href='/console'>
              <i className='bi bi-arrow-left-short'></i> Go home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
