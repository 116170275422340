export function DocumentPlusIcon() {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.37533 16.1842H6.45866C5.53818 16.1842 4.79199 15.4381 4.79199 14.5176V5.76758C4.79199 4.8471 5.53818 4.10091 6.45866 4.10091H11.667L15.2087 7.64258V9.51758'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.167 12.4342V16.1842'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.042 14.3092H12.292'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.9997 7.85091H11.458V4.30925'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export function UploadedDocumentPlusIcon() {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.142578'
        width='20'
        height='20'
        rx='4'
        fill='#FF8D06'
        fill-opacity='0.1'
      />
      <path
        d='M9.87484 16.1839H6.95817C6.0377 16.1839 5.2915 15.4377 5.2915 14.5173V5.76725C5.2915 4.84678 6.0377 4.10059 6.95817 4.10059H12.1665L15.7082 7.64225V9.51725'
        stroke='#FF8D06'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.6665 12.4346V16.1846'
        stroke='#FF8D06'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.5415 14.3096H12.7915'
        stroke='#FF8D06'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.5002 7.85124H11.9585V4.30957'
        stroke='#FF8D06'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
