import { useEffect, useMemo, useState } from "react";
import { ChevronRight } from "../../assets/chevrons";
import { ImageWithFallback } from "../../pages/console";
import { Counter } from "../counter";
import {
  errorHandler,
  formatMoney,
  LSUserData,
  renderErrorToast,
} from "../../utils/functions";
import moment from "moment";
import axios from "axios";
import { Loader } from "../loader";

export function DistributorDeliveryDrawer({ selectedOrder, next }) {
  const [items, setItems] = useState([]);
  const { apiKey } = LSUserData() || {};
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedOrder) return setItems([]);
    setItems(
      selectedOrder?.items?.map((el) => ({
        ...el,
        quantityShipped: el.quantity,
        count: el.quantity,
      }))
    );
  }, [selectedOrder]);

  const handleSubmitDelivery = async () => {
    if (!totalShippedQuantity)
      return renderErrorToast(
        "Quantity Required",
        "Input the delivered quantity to ship this order",
        "items-err"
      );
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/orders/ship`,
        { items },
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      renderErrorToast(
        "Action completed!",
        "Your order has been shipped successfully",
        "ship-success-1"
      );
      next();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const {
        message = "Unable to deliver these orders. Please contact support",
      } = errorHandler(error);
      renderErrorToast(
        "Unable to complete action",
        message,
        "error-delivery-1"
      );
    }
  };

  const checkIfProductExists = (item) => {
    const productIndex = items.findIndex((el) => el._id === item?._id);
    if (productIndex < 0) {
      renderErrorToast(
        "Unable to increase product",
        "Item does not exist in state",
        "add-product-err"
      );
      return { exists: false };
    }
    return { exists: true, productIndex };
  };

  const handleAddProduct = (item) => {
    const { exists, productIndex } = checkIfProductExists(item);
    if (!exists) return;
    if (items[productIndex].count + 1 > items[productIndex].quantity)
      return renderErrorToast(
        "Unable to update item",
        "You cannot ship more than the quantity ordered for the item",
        "ship-count-err"
      );
    items[productIndex] = {
      ...items[productIndex],
      quantityShipped: items[productIndex].count + 1,
      count: items[productIndex].count + 1,
    };
    setItems([...items]);
  };

  const handleRemoveProduct = (item) => {
    const { exists, productIndex } = checkIfProductExists(item);
    if (!exists) return;
    if (items[productIndex].count < 1)
      return renderErrorToast(
        "Unable to update item",
        "You minimum delivery is 0",
        "remove-product-0"
      );
    items[productIndex] = {
      ...items[productIndex],
      quantityShipped: items[productIndex].count - 1,
      count: items[productIndex].count - 1,
    };
    setItems([...items]);
  };

  const handleProductInput = (item, value) => {
    const { exists, productIndex } = checkIfProductExists(item);
    if (!exists) return;
    if (value < 1)
      return renderErrorToast(
        "Unable to update item",
        "You minimum delivery is 0",
        "remove-product-0"
      );
    if (value > items[productIndex].quantity)
      return renderErrorToast(
        "Unable to update item",
        "You cannot ship more than the quantity ordered for the item",
        "ship-count-err-1"
      );
    items[productIndex] = {
      ...items[productIndex],
      quantityShipped: value,
      count: value,
    };
    setItems([...items]);
  };

  const totalShippedQuantity = useMemo(() => {
    if (!items) return;
    return items.reduce((acc, curr) => acc + curr?.quantityShipped ?? 0, 0);
  }, [items]);

  return (
    <div
      className='offcanvas offcanvas-end tdp_offcanvas shipment_details distributor-delivery'
      tabindex='-1'
      id='distributorDeliveryCanvasExample'
      aria-labelledby='distributorDeliveryCanvasExampleLabel'
    >
      <div className='offcanvas-header'>
        <h5
          className='offcanvas-title'
          id='distributorDeliveryCanvasExampleLabel'
        >
          <i
            className='bi bi-x-lg'
            // data-bs-dismiss='payout-details'
            aria-label='Close'
            id='close'
            data-bs-toggle='offcanvas'
            href='#distributorDeliveryCanvasExample'
            role='button'
            aria-controls='distributorDeliveryCanvasExample'
          ></i>
          Orders
          <p>
            <ChevronRight /> {selectedOrder?.orderNumber}
          </p>
        </h5>
      </div>
      <div className='body-container'>
        <div className='offcanvas-body'>
          <div className='heading'>
            <h5>{selectedOrder?.orderNumber}</h5>
            <div className='info'>
              <div>
                <p className='title'>Amount</p>
                <p className='value'>
                  {selectedOrder?.currency?.symbol}
                  {formatMoney(selectedOrder?.total)}{" "}
                  {selectedOrder?.currency?.iso}
                </p>
              </div>
              <div>
                <p className='title'>Delivering To</p>
                <p className='value'>
                  {selectedOrder?.shippingAddress?.company}
                </p>
              </div>
              <div>
                <p className='title'>Created On</p>
                <p className='value'>
                  {moment(selectedOrder?.createdAt).format("D MMM, YYYY")}
                </p>
              </div>
              <div>
                <p className='title'>Status</p>
                <p className='value'>{selectedOrder?.shippingStatus}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='offcanvas-body distributor-delivery-body'>
          <h5>Review and confirm items and quantity</h5>
          {(items ?? []).map((product, key) => (
            <div className='item-container' key={key}>
              <div className='product-detail'>
                <ImageWithFallback
                  fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product?.variantId}.png`}
                  src={`${process.env.REACT_APP_PRODUCT_URL}/${product?.variantId}.png`}
                  alt={product?.name || product?.description}
                  key={product?.variantId}
                />
                <h6>{product?.name}</h6>
              </div>
              <div className='quantity-detail'>
                <h6>Quantity</h6>
                <Counter
                  handleInputProductQuantity={(item, value) =>
                    handleProductInput(item, value)
                  }
                  handleAddProduct={(item) => handleAddProduct(item)}
                  product={product}
                  handleRemoveProduct={(item) => handleRemoveProduct(item)}
                />
              </div>
            </div>
          ))}
          <button disabled={loading} onClick={handleSubmitDelivery}>
            {loading ? <Loader /> : "Confirm delivery"}
          </button>
        </div>
      </div>
    </div>
  );
}
