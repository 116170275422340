/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import { InquirySvg } from "../assets/inquiry-icon";
import { useNavigate } from "react-router-dom";
import {
  errorHandler,
  formatMoney,
  isUserLoggedIn,
  LSUserData,
  renderErrorToast,
} from "../utils/functions";
import { CustomCalendarDatePicker } from "../components/custom-calendar";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { InvoiceView } from "../components/invoice-view";
import {
  RecentOrdersTable,
  UnpaidInvoicesTable,
} from "../components/console.tables";
import moment from "moment";
import { LoadingProduct } from "../components/drawers/products.drawer";
import { triggerDownloadInvoice } from "./invoices";
import refreshOutlet from "../utils/refreshOutlet";
import { CustomSelect } from "../components/custom-select";
import { isAnExportOutlet } from "../utils/constants";
import { CatalogProductDetail } from "../components/drawers/catalog-product.drawer";

export function ImageWithFallback({
  src = "",
  fallback = "",
  alt = "",
  isBigScreen = false,
  isListScreen = false,
  ...rest
}) {
  const [imgSrc, setImgSrc] = useState(src); // Track current image source
  const [hasFallback, setHasFallback] = useState(false); // Track if fallback has been used

  const handleError = () => {
    if (!hasFallback) {
      // If fallback has not been used yet, switch to fallback image
      setImgSrc(fallback);
      setHasFallback(true);
    } else setImgSrc("/images/empty-state-products.png");
  };

  return (
    <div
      className='image-wrapper'
      style={
        isBigScreen
          ? { maxWidth: "250px", maxHeight: "250px", display: "flex" }
          : isListScreen
          ? { maxWidth: "45px", maxHeight: "49px", display: "flex" }
          : { maxWidth: "90px", maxHeight: "90px", display: "flex" }
      }
    >
      <img
        src={imgSrc}
        onError={handleError}
        alt={alt}
        className='img-fluid'
        style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        {...rest}
      />
    </div>
  );
}

export function Console() {
  const scrollContainerRef = useRef(null);
  const [selectedDates, setSelectedDates] = useState([
    moment().subtract(30, "days").format(),
    moment().format(),
  ]);
  const [stats, setStats] = useState({
    orderVolume: {},
    ordersInProgress: {},
    unpaidInvoice: {},
  });
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [showing, setShowing] = useState("unpaid-invoices");
  const [loadingUnpaidInvoices, setLoadingUnpaidInvoices] = useState(true);
  const [loadingRecentOrders, setLoadingRecentOrders] = useState(true);
  const [loadingStats, setLoadingStats] = useState(true);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const userIsLoggedIn = isUserLoggedIn();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showDaysRange, setShowDaysRange] = useState(false);
  const [selectedRange, setSelectedRange] = useState("Last 30 Days");
  const [downloading, setDownloading] = useState(false);
  const [sliderPositions, setSliderPositions] = useState({
    isAtEnd: false,
    isAtStart: true,
  });
  const { retailOutlets: [retailOutlet] = [], apiKey = "" } =
    LSUserData() || {};
  const {
    _id,
    company,
    account: { accountNumber = "", bankName = "" },
    // documents,
    country,
    kyc,
    incoterms,
  } = retailOutlet || {};
  const UNPAID_INVOICES = "unpaid-invoices";
  const RECENT_ORDERS = "recent-orders";

  useEffect(() => {
    if (!userIsLoggedIn) navigate("/login");
    refreshOutlet();
  }, [navigate, userIsLoggedIn]);

  useEffect(() => {
    handleFetchOutletStats();
    if (!unpaidInvoices.length) handleFetchUnpaidInvoices();
    if (!recentOrders.length) handleFetchRecentOrders();
  }, []);

  useEffect(() => {
    handleFetchOutletStats();
  }, [selectedDates]);

  const handleSaveDaysRange = (range, span) => {
    const startDate = moment().subtract(range, span).format();
    const endDate = moment().format();
    setSelectedDates([startDate, endDate]);
  };

  const handleDownloadInvoice = async (invoice) => {
    try {
      setDownloading(true);
      const invoiceUrl = await triggerDownloadInvoice(invoice, apiKey);
      window.open(invoiceUrl, "_blank");
      setDownloading(false);
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error downloading Invoice",
        message,
        "download-invoice-err"
      );
    }
  };

  const handleFetchOutletStats = async () => {
    setLoadingStats(true);
    let filters = {};
    if (selectedDates.length)
      filters = { startDate: selectedDates[0], endDate: selectedDates[1] };
    const queryString = new URLSearchParams(filters).toString();
    try {
      const {
        data: {
          body: { frequentlyOrdered, openInvoices, openOrders, orderVolume },
        },
      } = await axios.get(
        `${process.env.REACT_APP_AWS_API_URL_V3}/procurement-stats?retailOutletId=${_id}&${queryString}`,
        { headers: { "x-api-key": apiKey } }
      );
      setStats({
        ordersInProgress: openOrders[0],
        orderVolume: orderVolume[0],
        unpaidInvoice: openInvoices[0],
      });
      setProducts(frequentlyOrdered);
    } catch (error) {
      console.log("fetching card stats error", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error fetching card stats",
        message,
        "fetching-card-stats"
      );
    } finally {
      setLoadingStats(false);
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      // const isAtEnd = scrollLeft + clientWidth > scrollWidth;
      const isAtStart = scrollLeft === 0;
      setSliderPositions({
        isAtEnd: scrollLeft + clientWidth > scrollWidth,
        isAtStart,
      });
    }
  };

  const handleFetchUnpaidInvoices = async () => {
    setLoadingUnpaidInvoices(true);
    try {
      const {
        data: {
          data: { invoices },
        },
      } = await axios.get(
        `${process.env.REACT_APP_AWS_API_URL_V2}/invoices?limit=5&batch=1&status=unpaid`,
        { headers: { "x-api-key": apiKey } }
      );
      setUnpaidInvoices(invoices);
    } catch (error) {
      console.log("handle fetch unpaid invoices err", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error fetching unpaid invoices",
        message,
        "error-fetching-unpaid-invoices-err"
      );
    } finally {
      setLoadingUnpaidInvoices(false);
    }
  };

  const handleFetchRecentOrders = async () => {
    setLoadingRecentOrders(true);
    try {
      const {
        data: {
          data: { transactions },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_APP_URL}/api/v3/procurement/transactions?limit=5`,
        { headers: { "x-api-key": apiKey } }
      );
      setRecentOrders(transactions.slice(0, 5));
    } catch (error) {
      console.log("error fetching recent orders", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error fetching recent orders",
        message,
        "error-fetching-recent-orders-err"
      );
    } finally {
      setLoadingRecentOrders(false);
    }
  };

  const smoothScroll = (element, target, duration) => {
    let start = element.scrollLeft,
      change = target - start,
      currentTime = 0,
      increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const handleScrollLeft = () => {
    if (scrollContainerRef.current)
      smoothScroll(
        scrollContainerRef.current,
        scrollContainerRef.current.scrollLeft - 500,
        400
      );
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current)
      smoothScroll(
        scrollContainerRef.current,
        scrollContainerRef.current.scrollLeft + 400,
        400
      );
  };

  const isOnboarded = useMemo(() => {
    if (country === "NG") {
      if (
        company?.kyb?.bvn &&
        company?.kyb?.businessType &&
        company?.kyb?.rcNumber &&
        accountNumber &&
        bankName
        // &&
        // documents
      )
        return true;
    } else {
      if (
        company?.kyb?.businessType &&
        company?.kyb?.rcNumber &&
        kyc?.idVerified
      )
        return true;
    }
    return false;
  }, [
    accountNumber,
    bankName,
    country,
    company?.kyb?.businessType,
    company?.kyb?.bvn,
    company?.kyb?.rcNumber,
    localStorage.getItem("userData"),
  ]);

  return (
    <div
      className={`tdp_console ${isOnboarded ? "" : "hasOnboarding"}`}
      onClick={() => {
        if (showDaysRange) setShowDaysRange(false);
      }}
    >
      {!isOnboarded ? (
        <div className='section _one'>
          <h3>Finish your onboarding</h3>
          <h6>Complete filling up your business details</h6>
          <button onClick={() => navigate("/onboarding")}>
            Add company information
          </button>
        </div>
      ) : null}
      <div className='section _two'>
        <h3>Overview</h3>
        <div className='time-range'>
          <CustomSelect
            placeholder='Last 30 Days'
            open={showDaysRange}
            setOpen={(e) => {
              e.stopPropagation();
              setShowDaysRange(!showDaysRange);
            }}
            valueToDisplay={selectedRange}
            items={[
              { title: "Last 30 Days" },
              { title: "Last Three Months" },
              { title: "Last Six Months" },
            ]}
            action={({ title }) => {
              if (title === "Last 30 Days") handleSaveDaysRange(30, "days");
              if (title === "Last Three Months")
                handleSaveDaysRange(3, "months");
              if (title === "Last Six Months") handleSaveDaysRange(6, "months");
              setSelectedRange(title);
              setShowDaysRange(false);
            }}
          />
          <CustomCalendarDatePicker
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
          />
        </div>
        <div className='volume-container'>
          <div className='vol__' onClick={() => navigate("/orders")}>
            <h3>Order Volume</h3>
            {loadingStats ? (
              <SkeletonTheme baseColor='#fff' highlightColor='#444'>
                <Skeleton count={1} />
              </SkeletonTheme>
            ) : (
              <div>
                <h6>
                  {stats?.orderVolume?.currency?.symbol}
                  {formatMoney(stats?.orderVolume?.amount || 0)}
                </h6>
              </div>
            )}
          </div>
          <div
            className='vol__'
            onClick={() => navigate("/orders", { state: { filter: "open" } })}
          >
            <h3>Orders in progress</h3>
            {loadingStats ? (
              <Skeleton count={1} style={{ width: "100%" }} />
            ) : (
              <div>
                <h6>
                  {stats?.ordersInProgress?.currency?.symbol}
                  {formatMoney(stats?.ordersInProgress?.amount || 0)}
                </h6>
              </div>
            )}
          </div>
          <div
            className='vol__'
            onClick={() =>
              navigate("/invoices", { state: { filter: "unpaid" } })
            }
          >
            <h3>Unpaid Invoices</h3>
            {loadingStats ? (
              <Skeleton count={1} style={{ width: "100%" }} />
            ) : (
              <div>
                <h6>
                  {stats?.unpaidInvoice?.currency?.symbol}
                  {formatMoney(stats?.unpaidInvoice?.amount || 0)}
                </h6>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='section _three'>
        <div className='container-table'>
          <div className='nav_buttons'>
            <button
              className={showing === UNPAID_INVOICES ? "active" : ""}
              onClick={() => setShowing(UNPAID_INVOICES)}
            >
              Unpaid Invoices
            </button>
            <button
              className={showing === RECENT_ORDERS ? "active" : ""}
              onClick={() => setShowing(RECENT_ORDERS)}
            >
              Recent Orders
            </button>
          </div>
          {loadingRecentOrders || loadingUnpaidInvoices ? (
            <Skeleton count={5} />
          ) : (
            <>
              {showing === UNPAID_INVOICES ? (
                <div className='nav_content'>
                  {!unpaidInvoices.length ? (
                    <div className='empty_content'>
                      <p>
                        <InquirySvg />
                      </p>
                      <div>
                        <h2>You currently have no unpaid invoices</h2>
                        <h4>
                          Unpaid invoices will show up here once a{" "}
                          <a href='/suppliers'>supplier</a> processes an{" "}
                          <a href='/orders'>order</a>.
                        </h4>
                        <a href='/invoices'>View all invoices &rarr;</a>
                      </div>
                    </div>
                  ) : (
                    <UnpaidInvoicesTable
                      unpaidInvoices={unpaidInvoices}
                      selectedInvoice={selectedInvoice}
                      downloading={downloading}
                      setSelectedInvoice={(el) => setSelectedInvoice(el)}
                      handleDownloadInvoice={(invoice) =>
                        handleDownloadInvoice(invoice)
                      }
                    />
                  )}
                </div>
              ) : (
                <div className='nav_content'>
                  {!recentOrders.length ? (
                    <div className='empty_content'>
                      <p>
                        <InquirySvg />
                      </p>
                      <div>
                        <h2>You currently have no orders</h2>
                        <h4>
                          Recent Orders will show up here once you create an{" "}
                          <a href='/create-order'>order</a>.
                        </h4>
                        <a href='/orders'>View all orders &rarr;</a>
                      </div>
                    </div>
                  ) : (
                    <RecentOrdersTable orders={recentOrders} />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {isAnExportOutlet(retailOutlet) ? null : (
        <div className='section _three'>
          <h1>Frequently ordered</h1>
          <div className='nav_content'>
            {loadingStats ? (
              <div className='products-loading-screen'>
                <LoadingProduct length={7} />
              </div>
            ) : !loadingStats && !products.length ? (
              <div className='empty_content'>
                <p>
                  <InquirySvg />
                </p>
                <div>
                  <h2>There's nothing new to show</h2>
                  <h4>
                    Products you purchase frequently will start to show here
                    once you start <a href='/create-order'>creating orders</a>.
                  </h4>
                  <a href='/orders'>View all orders &rarr;</a>
                </div>
              </div>
            ) : (
              <div className='slide-frequent-orders'>
                <div className='frequent-orders' ref={scrollContainerRef}>
                  {isAnExportOutlet(retailOutlet) ? (
                    <ExportOrdersFrequentlyOrderedProducts
                      products={products}
                      incoterms={incoterms}
                    />
                  ) : (
                    products
                      .filter((el) => el.variantId)
                      .map((product) => {
                        return (
                          <div className='product'>
                            <ImageWithFallback
                              src={`https://pimg.tradedepot.co/xs/${product?.variantId}.png`}
                              fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product?.variantId}.png`}
                              alt={product?.name}
                              key={product?._id}
                            />
                            <div className='details'>
                              <h3>
                                {product?.currency?.symbol}
                                {formatMoney(product?.price || 0)}
                              </h3>
                              <p className='name'>{product?.name}</p>
                              {/* <p className='quantity'>70g X 24</p> */}
                              <button
                                onClick={() =>
                                  navigate("/create-order", {
                                    state: {
                                      selectedProducts: [
                                        { ...product, count: 1 },
                                      ],
                                    },
                                  })
                                }
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        );
                      })
                  )}
                </div>
                <div className='slider-buttons'>
                  <button
                    className={`${sliderPositions.isAtStart ? "hide" : ""}`}
                    onClick={() => {
                      handleScrollLeft();
                      handleScroll();
                    }}
                  >
                    <i class='bi bi-chevron-left'></i>
                  </button>
                  <button
                    onClick={() => {
                      handleScrollRight();
                      handleScroll();
                    }}
                    className={`right ${sliderPositions.isAtEnd ? "hide" : ""}`}
                  >
                    <i class='bi bi-chevron-right'></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <InvoiceView
        handleDownloadInvoice={(invoice) => handleDownloadInvoice(invoice)}
        invoice={selectedInvoice}
        downloading={downloading}
      />
    </div>
  );
}

export const ExportOrdersFrequentlyOrderedProducts = ({
  products,
  incoterms,
}) => {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const variants = products.map((el) => ({
    ...el,
    currency: el?.suppliers?.[0]?.currency,
    price: el?.suppliers?.[0]?.[incoterms?.toLowerCase()] || 0,
    convertedPrice: el?.suppliers?.[0]?.[incoterms?.toLowerCase()] || 0,
    supplier: el?.suppliers?.[0]?.companyId,
    convertedCurrency: el?.suppliers?.[0]?.currency?.symbol,
    incoterms,
  }));

  return (
    <>
      {variants.map((product, key) => (
        <>
          <div
            className='product'
            key={key}
            data-bs-toggle='offcanvas'
            href='#offcanvasCatalogProduct'
            aria-controls='offcanvasCatalogProduct'
          >
            <ImageWithFallback
              src={`https://pimg.tradedepot.co/xs/${product?.variantId}.png`}
              fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product?.variantId}.png`}
              alt={product?.name}
              key={product?._id}
            />
            <div className='details'>
              <h3>
                {product?.currency?.symbol}
                {formatMoney(product?.price || 0)}
              </h3>
              <p className='name'>{product?.name}</p>
              {/* <p className='quantity'>70g X 24</p> */}
              <button
                onClick={() => setSelectedProduct(product)}
                // onClick={() =>
                //   navigate("/create-order", {
                //     state: {
                //       selectedProducts: [{ ...product, count: 1 }],
                //     },
                //   })
                // }
              >
                View
              </button>
            </div>
          </div>
        </>
      ))}
      <CatalogProductDetail
        isExportOutlet={true}
        product={selectedProduct}
        handleAddProduct={(product, supplierId) => {
          const pricing = product?.suppliers?.find(
            (el) => el.companyId === supplierId
          );
          const selectedProd = {
            ...product,
            count: 1,
            price: pricing[incoterms?.toLowerCase()],
            convertedPrice: pricing[incoterms?.toLowerCase()],
          };
          navigate("/create-order", {
            state: {
              selectedProducts: [selectedProd],
            },
          });
        }}
        cartProducts={[]}
        handleInputProductQuantity={(product, value, supplierId) => {
          const pricing = product?.suppliers?.find(
            (el) => el.companyId === supplierId
          );
          const selectedProd = {
            ...product,
            count: Number(value),
            price: pricing[incoterms?.toLowerCase()],
            convertedPrice: pricing[incoterms?.toLowerCase()],
          };
          return navigate("/create-order", {
            state: {
              selectedProducts: [selectedProd],
            },
          });
        }}
        handleRemoveProduct={null}
      />
    </>
  );
};
