import { toast } from "react-toastify";

export function CustomToast({ title, bodyText, action, children }) {
  return (
    <div className='tdp_customtoast tdp_modal'>
      <>
        <div className='action_'>
          <i class='bi bi-x-lg' onClick={() => toast.dismiss()}></i>
        </div>
        <h2>{title}</h2>
        <h5>{bodyText}</h5>
        {children}
        <div className='action_'>
          {/* <button
          // onClick={() => {
          //   navigate("/console");
          //   toast.dismiss();
          // }}
          >
            Back to Home
          </button> */}
        </div>
      </>
    </div>
  );
}
