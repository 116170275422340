export function LocationIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.1665 4.50033L6.1665 3.16699V11.5003L3.1665 12.8337V4.50033Z'
        stroke='white'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.8335 4.50033L12.8335 3.16699V11.5003L9.8335 12.8337V4.50033Z'
        stroke='white'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.83317 4.50033L6.1665 3.16699V11.5003L9.83317 12.8337V4.50033Z'
        stroke='white'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
