export function InvoiceSvg() {
  return (
    <svg
      width='28'
      height='29'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.4584 14.7917V8.375C22.4584 7.08634 21.4137 6.04167 20.1251 6.04167H7.87508C6.58642 6.04167 5.54175 7.08634 5.54175 8.375V20.625C5.54175 21.9137 6.58642 22.9583 7.87508 22.9583H13.1251M10.2084 10.7083H17.7917M10.2084 15.375H17.7917M17.2084 21.2083L18.9584 22.9583C19.8334 20.3333 22.4584 18.875 22.4584 18.875'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export function SalesInvoiceSvg() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 8.00003C10 8.00003 10.5 8.25003 12 8.25003C13.5 8.25003 14 8.00003 14 8.00003C14 8.00003 15.75 5.75003 18 5.75003M4.75 15C4.75 18 8 19.2498 12 19.2498C16 19.2498 19.25 17.9998 19.25 14.9998C19.25 13.4349 17.5494 10.87 15.9224 9.73093C14.3213 8.60997 13.4252 6.61786 14 4.74976H10C10.5748 6.61788 9.67878 8.61 8.07765 9.73102C6.45061 10.8702 4.75 13.4351 4.75 15Z'
        stroke='#141414'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
