export function BuildingIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.62565 16.0418H14.3757C15.2961 16.0418 16.0423 15.2956 16.0423 14.3752V6.81931C16.0423 6.58139 15.9914 6.34623 15.8929 6.12964L15.4447 5.14362C15.1743 4.54863 14.581 4.16663 13.9275 4.16663H6.07384C5.42027 4.16663 4.82701 4.54863 4.55656 5.14362L4.10837 6.12964C4.00992 6.34623 3.95898 6.58139 3.95898 6.81931V14.3752C3.95898 15.2956 4.70518 16.0418 5.62565 16.0418Z'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.91634 6.45837C7.91634 7.49391 7.08301 8.54171 5.83301 8.54171C4.58301 8.54171 3.95801 7.49391 3.95801 6.45837'
        stroke='#141414'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.0413 6.45837C16.0413 7.49391 15.4163 8.54171 14.1663 8.54171C12.9163 8.54171 12.083 7.49391 12.083 6.45837'
        stroke='#141414'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.0837 6.45837C12.0837 7.49391 11.2503 8.54171 10.0003 8.54171C8.75033 8.54171 7.91699 7.49391 7.91699 6.45837'
        stroke='#141414'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.125 13.1244C8.125 12.204 8.87119 11.4578 9.79167 11.4578H10.2083C11.1288 11.4578 11.875 12.204 11.875 13.1244V16.0411H8.125V13.1244Z'
        stroke='#141414'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
