/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { ChevronRight } from "../../assets/chevrons";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { Loader } from "../loader";
import { DownloadIcon } from "../../assets/download.icon";
import {
  formatMoney,
  LSUserData,
  renderErrorToast,
} from "../../utils/functions";
import { InvoiceView } from "../invoice-view";
import axios from "axios";

export function PayoutViewModal({ payout }) {
  const [viewType, setViewType] = useState("invoices");
  return (
    <div
      className='offcanvas offcanvas-end tdp_offcanvas shipment_details payout-details'
      tabindex='-1'
      id='payoutOffCanvasExample'
      aria-labelledby='payoutOffCanvasExampleLabel'
    >
      <div className='offcanvas-header'>
        <h5 className='offcanvas-title' id='payoutOffCanvasExampleLabel'>
          <i
            className='bi bi-x-lg'
            data-bs-dismiss='payout-details'
            aria-label='Close'
            id='close'
            data-bs-toggle='offcanvas'
            href='#payoutOffCanvasExample'
            role='button'
            aria-controls='payoutOffCanvasExample'
          ></i>
          Payouts
          <p>
            <ChevronRight /> {payout?.reference}
          </p>
        </h5>
      </div>
      <div className='body-container'>
        <div className='offcanvas-body'>
          <div className='heading'>
            <h5>{payout?.reference}</h5>
            <div className='info'>
              <div>
                <p className='title'>Amount Sent</p>
                <p className='value'>
                  <span> ₦{formatMoney(payout?.amount)}</span> NGN
                </p>
              </div>
              <div>
                <p className='title'>Receiving Bank</p>
                <p className='value'>
                  {payout?.bankAccount?.bank}
                  <span>
                    <i className='bi bi-dot'></i>
                    {payout?.bankAccount?.accountNumber.slice(-4)}
                  </span>
                </p>
              </div>
              <div>
                <p className='title'>Fees</p>
                <p className='value'>
                  <span>₦{formatMoney(payout?.serviceCharge)}</span> NGN
                </p>
              </div>
              <div>
                <p className='title'>Status</p>
                <p className='value'>
                  <span>{payout?.status}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='offcanvas-body info-container'>
          <div className='information-view'>
            <div className='filter-buttons'>
              <button
                className={viewType === "invoices" ? "active" : ""}
                onClick={() => setViewType("invoices")}
              >
                Invoices
              </button>
            </div>
            {viewType === "invoices" ? (
              <Invoices invoices={payout?.invoices} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

const Invoices = ({ invoices, loading }) => {
  const { apiKey } = LSUserData() || {};
  const [downloading, setDownloading] = useState(false);
  const [invoiceToDownload, setInvoiceToDownload] = useState(null);

  const handleDownloadInvoice = async (invoice) => {
    setInvoiceToDownload(invoice);
    try {
      setDownloading(true);
      const {
        data: {
          data: { invoiceUrl },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/manager-invoice/${invoice?.retailOutletId}/${invoice?.orderIds?.[0]}`,
        { headers: { "x-api-key": apiKey } }
      );
      window.open(invoiceUrl, "_blank");
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      return renderErrorToast(
        "Invoice download error",
        "There was a problem downloading this invoice",
        "invoice-download-err"
      );
    }
  };

  return (
    <div className='info-box'>
      <div className='info-body'>
        <div className='containers invoices documents'>
          <h3>Invoices</h3>
          <table className='table'>
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th>Amount paid out</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? [1, 2].map((_) => <Skeleton />)
                : invoices?.map((invoice, _id) => (
                    <tr key={_id}>
                      <td
                        onClick={() => {
                          const invoiceModalInstance = document.getElementById(
                            "offcanvasInvoiceView"
                          );
                          const invoiceModal = new window.bootstrap.Offcanvas(
                            invoiceModalInstance
                          );
                          invoiceModal.show();
                        }}
                      >
                        <a onClick={() => setInvoiceToDownload(invoice)}>
                          {invoice?.invoiceNumber}
                        </a>
                      </td>
                      <td>{moment(invoice?.createdAt).format("DD/MM/YYYY")}</td>
                      <td className='amount'>
                        <span>
                          {invoice?.currency?.symbol}
                          {formatMoney(invoice?.amount)}
                        </span>{" "}
                        {invoice?.currency?.iso}
                      </td>
                      <td>
                        <div
                          className='download'
                          disabled={downloading}
                          onClick={() => handleDownloadInvoice(invoice)}
                        >
                          {downloading &&
                          invoiceToDownload?._id === invoice?._id ? (
                            <Loader />
                          ) : (
                            <DownloadIcon />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <InvoiceView
          invoice={invoiceToDownload}
          handleDownloadInvoice={(invoice) => handleDownloadInvoice(invoice)}
          downloading={downloading}
          setDownloading={setDownloading}
        />
      </div>
    </div>
  );
};
