import moment from "moment";
import { relativeStatus } from "../utils/constants";
import { formatMoney } from "../utils/functions";

export function InvoiceView({ invoice, handleDownloadInvoice, downloading }) {
  const currencySymbol = invoice?.currency?.symbol || "";

  return (
    <div
      className='offcanvas offcanvas-end tdp_offcanvas'
      tabindex='-3'
      id='offcanvasInvoiceView'
      aria-labelledby='offcanvasInvoiceViewLabel'
    >
      <div className='offcanvas-header header-invoice-view'>
        <h5 className='offcanvas-title' id='offcanvasInvoiceViewLabel'>
          <i
            className='bi bi-x-lg'
            data-bs-dismiss='offcanvas_invoice'
            data-bs-toggle='offcanvas'
            href='#offcanvasInvoiceView'
            aria-controls='offcanvasInvoiceView'
            aria-label='Close'
            id='close'
          ></i>
          Invoice Summary
        </h5>
        <div className='action-buttons'>
          <button
            disabled={downloading}
            onClick={() => handleDownloadInvoice(invoice)}
          >
            {downloading ? (
              <div
                class='spinner-border text-success app-spinner'
                role='status'
              >
                <span class='sr-only'></span>
              </div>
            ) : (
              "Print"
            )}
          </button>
        </div>
      </div>
      <div className='offcanvas-body invoice-view-body'>
        <h2>Invoice #{invoice?.invoiceNumber}</h2>
        <div className='due-date'>
          {invoice
            ? invoice.status !== "paid" && (
                <p>Due {moment(invoice.dueAt).format("MMMM D, YYYY")}</p>
              )
            : null}
          <span
            className={`custom_status ${relativeStatus(invoice?.status)} ${
              invoice?.status === "paid" ? "paid" : ""
            }`}
          >
            {invoice?.status}
          </span>
        </div>
        <div className='addresses'>
          <div>
            <p>Deliver To</p>
            <p>{`${invoice?.outletBusinessName} || ${invoice?.shippingAddress?.address1}`}</p>
          </div>
        </div>
        <div className='_custom-table'>
          <div className='_head'>
            <p>Description</p>
            <p>Qty</p>
            <p>Unit Price</p>
            <p>Discount</p>
            <p>Tax</p>
            <p>Amount</p>
          </div>
          <div className='_body'>
            {invoice?.items.map((el, key) => (
              <tr key={key}>
                <td className='item-1'>{el.name}</td>
                <td className='item-2'>{el.quantity}</td>
                <td className='item-2'>
                  {currencySymbol} {formatMoney(el.price)}
                </td>
                <td className='item-1'>
                  {currencySymbol} {el.discount || 0}
                </td>
                <td className='item-1'>
                  {currencySymbol} {el.tax || 0}
                </td>
                <td className='item-1'>
                  {currencySymbol} {formatMoney(el.price * el.quantity || 0)}
                </td>
              </tr>
            ))}
          </div>
          <div className='_totals'>
            <div className='total-line'>
              <p>Subtotal</p>
              <p>
                {currencySymbol} {formatMoney(invoice?.subTotal || 0)}
              </p>
            </div>
            <div className='total-line'>
              <p>Discounts</p>
              <p>
                {currencySymbol} {formatMoney(invoice?.shippingDiscounts || 0)}
              </p>
            </div>
            <div className='total-line'>
              <p>Taxes</p>
              <p>
                {currencySymbol} {formatMoney(invoice?.taxes || 0)}
              </p>
            </div>
            <div className='total-line'>
              <p>Shipping Costs</p>
              <p>
                {currencySymbol} {formatMoney(invoice?.shippingCosts || 0)}
              </p>
            </div>
            <div className='total-line paid'>
              <p>Paid amount</p>
              <p>
                {currencySymbol}{" "}
                {formatMoney(invoice?.total - invoice?.amount ?? 0)}
              </p>
            </div>
            <div className='total-line total'>
              <p>Amount Due</p>
              <p>
                {currencySymbol} {formatMoney(invoice?.amount)}
              </p>
            </div>
          </div>
          {invoice && invoice?.status !== "paid" ? (
            <div className='payment-instruction'>
              <h4>How to pay</h4>
              <h5>
                <b>
                  Pay {currencySymbol}
                  {formatMoney(invoice.amount)}
                </b>{" "}
                with bank transfer
              </h5>
              <h6>Transfer funds using the following bank information.</h6>
              <div className='bank-details'>
                <div className=''>
                  <p>Account Number</p>
                  <p>{invoice?.bankAccount?.accountNumber || "- "}</p>
                </div>
                <div className=''>
                  <p>Bank Name</p>
                  <p>{invoice?.bankAccount?.bankName || "- "}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
