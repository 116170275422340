export function MobileView() {
  return (
    <div className='tdp_mobile'>
      <img
        src='/images/icons/icon.svg'
        alt='td-icon-svg'
        className='img-fluid'
      />
      <h4>
        We currently do not have this view for mobile at the moment. Please, try
        again later!
      </h4>
    </div>
  );
}
