import { ProductsStructure } from "../products-structure";

export function CategoryProductsDrawer({
  products = [],
  categoryName = "",
  setSearchText,
  searchText,
  cartProducts,
  handleAddProduct,
}) {
  return (
    <div className='category-products-container'>
      <div
        className='offcanvas offcanvas-end tdp_offcanvas tdp_catalog_category_products'
        tabindex='-1'
        id='offcanvasCategoryProducts'
        aria-labelledby='offcanvasCategoryProductsLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvas-CategoryProductsLabel'>
            <i
              className='bi bi-x-lg'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='close-category-products-drawer'
            ></i>
            <p>{categoryName}</p>
          </h5>
        </div>
        <div className='offcanvas-header header-2'>
          <div className='search-container'>
            <i className='bi bi-search'></i>
            <input
              placeholder='Search'
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
            />
          </div>
        </div>
        <div className='offcanvas-body'>
          <div className='tdp_product_catalog'>
            <ProductsStructure
              searchText={searchText}
              selectedCategory={
                categoryName ? null : { categoryId: categoryName }
              }
              products={products}
              cartProducts={cartProducts}
              handleAddProduct={handleAddProduct}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
