import ReactPaginate from "react-paginate";

export function Pagination({
  dataCount = 0,
  perPage = 0,
  setCurrentPage,
  currentPage = 1,
}) {
  const pageCount = Math.ceil(dataCount / perPage);
  return (
    <div className='custom_pagination'>
      <ReactPaginate
        breakLabel='...'
        nextLabel='>'
        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        pageRangeDisplayed={4}
        pageCount={pageCount}
        previousLabel='<'
        forcePage={currentPage - 1}
        disableInitialCallback={true}
        renderOnZeroPageCount={null}
        onPageActive={({ selected }) => {
          console.log("sd", selected);
        }}
        activeClassName='active'
      />
    </div>
  );
}
