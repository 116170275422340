import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  businessStatuses,
  SHOP,
  SHOPTOPUP_DOMAIN,
  TD_DOMAIN,
} from "../utils/constants";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  errorHandler,
  numberHasString,
  renderErrorToast,
  validateEmail,
  validatePhoneNumberWithGoogle,
} from "../utils/functions";
import { Kiosk } from "../assets/kiosk.icon";
import { toast } from "react-toastify";
import { CustomToast } from "../components/toast-component";
import useTimer from "../hooks/useTimer";
import { Person2 } from "../assets/person2.icon";
import { TDIcon } from "../assets/td.icon";
import { LegalComplianceIcon } from "../assets/legal-compliance.icon";
import { CreditNoteIcon } from "../assets/credit-note.icon";
import { UploadIcon } from "../assets/upload.icon";
import {
  DocumentPlusIcon,
  UploadedDocumentPlusIcon,
} from "../assets/document-plus.icon";
import { RegisterScreenOneSchema } from "../utils/schema";
import { Loader } from "../components/loader";
import { CustomSelect } from "../components/custom-select";
import { COUNTRIES } from "../utils/countries";

export function Register() {
  // const inputRef = useRef(null);
  const [enterAddressManually, setAddressManualInput] = useState(false);
  const [showAddressSelectContainer, setShowAddressSelectContainer] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [registrationSteps, setRegistrationSteps] = useState(1);
  const [loadingAddress, setLoadingAddress] = useState("");
  const [addressContainers, setAddressContainers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [storeTypes, setStoreTypes] = useState([]);
  const [resendingToken, setIsResendingToken] = useState(false);
  // const [countryAddress, setCountryAddress] = useState(""); // used for addresses of country that are not GB
  const [step, setStep] = useState(1); //1 - address containers, 2 - address list, 3 - retrieve address
  const [seconds, setStartCountdown] = useTimer();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contactPosition: "",
      outletBusinessName: "",
      outletTypeId: "",
      outletPhoneNumber: "",
      outletEmail: "",
      outletAddress: "",
    },
    onSubmit: (values) => handleNextSteps(values),
    validationSchema: registrationSteps === 2 ? RegisterScreenOneSchema : null,
  });
  const navigate = useNavigate();

  const handleNextSteps = (values) => {
    if (registrationSteps === 2) {
      setLoading(true);
      const isEmailValid = validateEmail(values.email.toLowerCase());
      if (!isEmailValid) {
        setLoading(false);
        return renderErrorToast(
          "Invalid Email",
          "Email format is invalid",
          "invalid-email-address-err"
        );
      }
      const isValidPhoneNumber = !numberHasString(values.phoneNumber);
      if (!isValidPhoneNumber)
        return renderErrorToast(
          "Invalid Phone Number",
          "The number provided is invalid. Please, check your phone number.",
          "invalid-phone-number-err-2"
        );
      if (!values.countryCode)
        return renderErrorToast(
          "Invalid Phone Number/Country",
          "The number provided does not match with address. Please, check your phone number.",
          "invalid-phone-number-err-3"
        );
      const { isNumberValid: numberIsValid, formattedNumber } =
        validatePhoneNumberWithGoogle(values.phoneNumber, values.countryCode);
      console.log({ formattedNumber, numberIsValid });
      if (!numberIsValid) {
        setLoading(false);
        return renderErrorToast(
          "Invalid Phone Number", // token
          "Please, check your phone number and provide it in a valid format", //message
          "invalid-phone" // toast-id
        );
      }
      formik.setFieldValue("formattedPhoneNumber", formattedNumber);
      checkIfEmailExists();
    } else if (registrationSteps === 1) {
      const isEmailValid = validateEmail(values.outletEmail.toLowerCase());
      if (!isEmailValid) {
        setLoading(false);
        return renderErrorToast(
          "Invalid Email",
          "Email format is invalid",
          "invalid-email-address-err-2"
        );
      }
      const isValidPhoneNumber = !numberHasString(values.outletPhoneNumber);
      if (!isValidPhoneNumber) {
        setLoading(false);
        return renderErrorToast(
          "Invalid Phone Number",
          "The number provided is invalid. Please, check your phone number.",
          "invalid-phone-number-err-2"
        );
      }
      if (!values.countryCode) {
        setLoading(false);
        return renderErrorToast(
          "Invalid Phone Number/Country",
          "The number provided does not match with address. Please, check your phone number.",
          "invalid-phone-number-err-3"
        );
      }
      const { isNumberValid: numberIsValid, formattedNumber } =
        validatePhoneNumberWithGoogle(
          values.outletPhoneNumber,
          values.countryCode
        );
      console.log({ formattedNumber, numberIsValid }, "step 1");
      if (!numberIsValid) {
        setLoading(false);
        return renderErrorToast(
          "Invalid Phone Number", // token
          "Please, check your phone number and provide it in a valid format", //message
          "invalid-phone" // toast-id
        );
      }
      formik.setFieldValue("formattedOutletPhoneNumber", formattedNumber);
      setRegistrationSteps(2);
    }
  };

  useEffect(() => {
    if (!formik.values.countryCode) return;
    handleFetchOutletTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.countryCode]);

  useEffect(() => {
    if (!formik.values.storeAddress?.length || !showAddressSelectContainer)
      return;
    const runFunc = setTimeout(async () => {
      setLoadingAddress(true);
      try {
        const {
          data: { data },
        } = await axios.get(
          `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/getAddress?country=GB&postCode=${formik.values.storeAddress}`
        );
        setAddressContainers(data);
        setLoadingAddress(false);
      } catch (error) {
        console.log("handle search address err", error);
        setLoadingAddress(false);
      }
    }, 2000);
    return () => clearTimeout(runFunc);
  }, [formik.values.storeAddress, showAddressSelectContainer]);

  // useEffect(() => {
  //   const googleScript = document.createElement("script");
  //   googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_ADDRESS_MAP_KEY}&libraries=places`;
  //   googleScript.async = true;
  //   googleScript.defer = true;

  //   googleScript.onload = () => {
  //     // Initialize Autocomplete after Google Maps script is loaded
  //     if (inputRef.current) {
  //       const autocomplete = new window.google.maps.places.Autocomplete(
  //         inputRef.current,
  //         {
  //           types: ["geocode"],
  //           componentRestrictions: {
  //             country: formik.values.countryCode,
  //           },
  //         }
  //       );

  //       autocomplete.addListener("place_changed", () => {
  //         const place = autocomplete.getPlace();
  //         formik.setFieldValue("latitude", place.geometry?.location.lat());
  //         formik.setFieldValue("longitude", place.geometry?.location.lng());
  //         formik.setFieldValue("storeAddress", place?.formatted_address || "");
  //         setCountryAddress(place?.formatted_address || "");
  //       });
  //     }
  //   };

  //   document.head.appendChild(googleScript);

  //   return () => {
  //     // Cleanup: remove the script when the component unmounts
  //     document.head.removeChild(googleScript);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [countryAddress]); // Empty dependency array ensures that this useEffect runs only once on mount

  const handleFetchOutletTypes = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(
        // `${process.env.REACT_APP_TD_APP_URL}/api/v4/procurement/retail/get-outlet-types?country=NG`
        `${process.env.REACT_APP_TD_APP_URL}/api/v4/procurement/retail/get-outlet-types?country=${formik.values.countryCode}`
      );
      setStoreTypes(data);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleFetchAddressFromContainer = async (container) => {
    console.log({ container });
    setStep(2);
    setAddressContainers([]);
    setLoadingAddress(true);
    const id = container.Id;
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/getAddress?country=${formik.values.countryCode}&postCode=${container.Text}&type=addressFind&id=${id}`
      );
      if (!data.length) {
        formik.setFieldValue("storeAddress", "");
        renderErrorToast(
          "Location Error!",
          "Sorry, we were unable to identify your location. Please enter a town, city or postcode.",
          "location-search-err"
        );
      }
      setAddresses(data);
      setLoadingAddress(false);
    } catch (error) {
      renderErrorToast(
        "Location Fetch Error!",
        "Sorry, we were unable to identify your location. Please enter a town, city or postcode.",
        "location-search-err"
      );
      setLoadingAddress(false);
    }
  };

  const handleRetrieveAddress = async (address) => {
    console.log("selected address", address);
    setStep(3);
    setAddresses([]);
    setLoadingAddress(true);
    const id = address.Id;
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/getAddress?country=${formik.values.countryCode}&postCode=${address.Text}&type=addressRetrieve&id=${id}`
      );
      if (!data) {
        formik.setFieldValue("storeAddress", "");
        renderErrorToast(
          "Location Error!",
          "Sorry, we were unable to identify your location. Please enter a town, city or postcode.",
          "location-search-err"
        );
      }
      const {
        City,
        CountryName,
        Line1,
        Line2,
        PostalCode,
        CountryIso2,
        CountryIso3,
      } = data;
      formik.setFieldValue("addressData", data);
      formik.setFieldValue(
        "storeAddress",
        `${Line1}, ${Line2}, ${City}, ${PostalCode} ${CountryName}`
      );
      formik.setFieldValue("countryCode", CountryIso2 || CountryIso3);
      setLoadingAddress(false);
      setShowAddressSelectContainer(false);
    } catch (error) {
      renderErrorToast(
        "Location Retrieval Error!",
        "Sorry, we were unable to identify your location. Please enter a town, city or postcode.",
        "location-search-err"
      );
      setLoadingAddress(false);
    }
  };

  const computeAddress = async (data) => {
    console.log("compute address", data);
    let addressLineOne = data.storeAddress;
    let addressLineTwo = "";
    let country = data.country;
    let city = data.countryCode;
    let postal = data.postcode;
    if (data.countryCode === "GB") {
      const {
        longitude = "",
        latitude = "",
        formattedAddress = "",
      } = await validateAddressWithGoogle(
        addressLineOne,
        addressLineTwo,
        postal
      );
      return {
        city,
        postalCode: postal,
        country,
        latitude,
        longitude,
        formattedAddress,
      };
    }
    return {
      city,
      postalCode: postal,
      country,
    };
  };

  const checkIfEmailExists = async () => {
    try {
      const {
        data: {
          data: { data },
        },
      } = await axios.get(
        `${
          process.env.REACT_APP_FIREBASE_URL
        }/shop/v4/getPhoneStatus?email=${formik.values.email.toLowerCase()}`
      );
      if (data) {
        setLoading(false);
        return renderErrorToast(
          "Registration Error",
          "User exists in our DB. Sign up with a different email address",
          "duplicate-user-err"
        );
      }
      sendUserEmailOTP();
    } catch (error) {
      const { message } = errorHandler(error);
      if (message.toLowerCase() === "outlet not found")
        return sendUserEmailOTP();
      setLoading(false);
      renderErrorToast(
        "Registration Error",
        message || "Error finding email duplicate",
        "user-validity-err"
      );
    }
  };

  const prepareData = async ({ accessToken: loginAccessToken }) => {
    try {
      const {
        city,
        postalCode,
        latitude = "",
        longitude = "",
        formattedAddress = "",
      } = await computeAddress(formik.values);
      const dataToSend = formik.values;
      if (!formik.values.longitude) dataToSend.longitude = longitude;
      if (!formik.values.latitude) dataToSend.latitude = latitude;
      if (formattedAddress) dataToSend.storeAddress = formattedAddress;
      if (!formik.values.city) dataToSend.city = city;
      if (!formik.values.postalCode) dataToSend.postcode = postalCode;
      handleCreateOutlet(loginAccessToken);
    } catch (error) {
      setLoading(false);
      console.log("hande register user", error);
    }
  };

  const sendUserEmailOTP = async (props) => {
    if (props?.isResending) setIsResendingToken(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/send-totp`,
        {
          mode: "email",
          email: formik.values.email.toLowerCase(),
          domain: TD_DOMAIN,
        }
      );
      setLoading(false);
      setIsResendingToken(false);
      setStartCountdown(true);
      setRegistrationSteps(3);
      setToken("");
      renderErrorToast(
        "Verification Token Sent!",
        `Please check your email (${formik.values.email}) for a verification token`,
        "sent-token-success"
      );
    } catch (error) {
      console.log("error sending user email otp");
      const { message } = errorHandler(error);
      toast(<CustomToast title={"Error sending token"} bodyText={message} />, {
        toastId: "err-1",
        position: "bottom-right",
      });
    }
  };

  const verifyUserToken = async () => {
    try {
      setLoading(true);
      setStartCountdown(false);
      const {
        data: { res },
      } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/verify-totp`,
        { email: formik.values.email.toLowerCase(), token }
      );
      prepareData(res);
    } catch (error) {
      console.log("sending user otp", error);
      setStartCountdown(true);
      const { message } = errorHandler(error);
      setLoading(false);
      toast(
        <CustomToast title={"Error verifying token"} bodyText={message} />,
        {
          toastId: "err-verifying-token",
          position: "bottom-right",
        }
      );
    }
  };

  const handleLogin = async (accessToken) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v4/loginWithToken`,
        { accessToken, email: formik.values.email.toLowerCase() }
      );
      localStorage.setItem("userData", JSON.stringify(data));
      navigate("/console");
    } catch (err) {
      setLoading(false);
      const { message } = errorHandler(err);
      renderErrorToast("Ooops!", message, "error-login-err");
    }
  };

  const validateAddressWithGoogle = async (
    addressLine1,
    addressLine2,
    postCode
  ) => {
    try {
      const {
        data: {
          result: {
            address: { formattedAddress },
            geocode: {
              location: { longitude, latitude },
            },
          },
        },
      } = await axios.post(
        `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_ADDRESS_MAP_KEY}`,
        {
          address: {
            regionCode: formik.values.countryCode,
            locality: formik.values.city,
            postalCode: postCode,
            addressLines: [addressLine1, addressLine2],
          },
        }
      );
      return { longitude, formattedAddress, latitude };
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error validating address",
        message,
        "validate-address-err"
      );
      console.log({ error }, "validating address with google");
    }
  };

  const progressPercentage = useMemo(() => {
    return `${(registrationSteps / 4) * 100}%`;
  }, [registrationSteps]);

  const handleCreateOutlet = async (loginAccessToken) => {
    try {
      const { values } = formik;
      console.log({ values });
      let streetName = values?.storeAddress || "";
      if (!streetName && values?.addressLine1 && values?.addressLine2)
        streetName = `${values?.addressLine1}, ${values?.addressLine2} ${values?.city}, ${values?.postcode} ${values?.country}`;
      const data = {
        outletBusinessName: values.outletBusinessName,
        contactRole: values.contactPosition,
        contactName: `${values.firstName} ${values.lastName}`,
        country: values.countryCode,
        email: values.outletEmail.toLowerCase(),
        gender: null,
        domain: values.countryCode === "GB" ? TD_DOMAIN : SHOPTOPUP_DOMAIN,
        streetName,
        lga: values?.addressData?.ProvinceName || "",
        state: values?.state || values?.countryCode,
        latitude: values?.latitude || "2.320",
        longitude: values?.longitude || "4.456",
        outletTypeId: values?.outletTypeId || "",
        extChannel: SHOP.WEB,
        contactPhone: values?.formattedPhoneNumber
          ? `${values.formattedPhoneNumber}`
          : "",
        businessStatus: values?.businessStatus || "",
        registrationNumber: values?.businessRegistrationNumber || "",
        taxId: null,
        phone: values.formattedOutletPhoneNumber,
        contactEmail: values.email.toLowerCase(),
        companyBankAccountName: values?.businessAccountName || "",
        companyBankAccountNumber: values?.businessAccountNumber || "",
        companyAccountSignatoryBvn: values?.businessAccountSignatoryBVN || "",
        images: values?.images || [],
        postCode: values?.postcode || "",
      };
      await axios.post(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/procurement/key_account/create`,
        data
      );
      handleLogin(loginAccessToken);
    } catch (error) {
      console.log("creating outlet err", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Oopss!",
        message ||
          "There was a problem with creating your outlet. Please check that all required fields are supplied and try again. If error persists, please contact support",
        "err-creating-outlet"
      );
      setLoading(false);
    }
  };

  return (
    <div className='tdp_register'>
      <div className='nav_controller'>
        <div className='title'>
          <i
            className='bi bi-chevron-left'
            onClick={() => {
              if (registrationSteps > 1)
                setRegistrationSteps(registrationSteps - 1);
            }}
          ></i>
          <h4>Create Outlet</h4>
        </div>
        <div
          className='progress'
          role='progressbar'
          aria-label='Success example'
        >
          <div
            class='progress-bar bg-success'
            style={{ width: progressPercentage }}
          />
        </div>
        <div></div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-4'></div>
          <div className='col-4 form-container'>
            {registrationSteps === 1 ? (
              <OutletRegistration
                formik={formik}
                storeTypes={storeTypes}
                enterAddressManually={enterAddressManually}
                setShowAddressSelectContainer={setShowAddressSelectContainer}
                loadingAddress={loadingAddress}
                addresses={addresses}
                addressContainers={addressContainers}
                handleFetchAddressFromContainer={
                  handleFetchAddressFromContainer
                }
                setAddressManualInput={setAddressManualInput}
                step={step}
                handleRetrieveAddress={handleRetrieveAddress}
                showAddressSelectContainer={showAddressSelectContainer}
              />
            ) : registrationSteps === 2 ? (
              <PersonalInformation formik={formik} loading={loading} />
            ) : registrationSteps === 3 ? (
              <ConfirmEmail
                token={token}
                sendUserEmailOTP={sendUserEmailOTP}
                verifyUserToken={verifyUserToken}
                loading={loading}
                seconds={seconds}
                setToken={setToken}
                setRegistrationSteps={setRegistrationSteps}
                resendingToken={resendingToken}
              />
            ) : null}
          </div>
          <div className='col-4'></div>
        </div>
      </div>
    </div>
  );
}

function PersonalInformation({ formik, loading }) {
  return (
    <div className='form-section'>
      <div className='form-icon'>
        <Person2 />
      </div>
      <div className='form-itself'>
        <h3>Contact Information</h3>
        <h5>Fill in the organization's contact details</h5>
        <div className='space-flex'>
          <div>
            <input
              className='form-control'
              placeholder='First name'
              value={formik.values.firstName?.trimStart()}
              onChange={({ target: { value } }) =>
                formik.setFieldValue("firstName", value)
              }
            />
          </div>
          <div>
            <input
              className='form-control'
              placeholder='Last Name'
              value={formik.values.lastName?.trimStart()}
              onChange={({ target: { value } }) =>
                formik.setFieldValue("lastName", value)
              }
            />
          </div>
        </div>
        <input
          className='form-control'
          placeholder='Email address'
          value={formik.values.email?.trimStart()}
          onChange={({ target: { value } }) =>
            formik.setFieldValue("email", value)
          }
        />
        <input
          className='form-control'
          placeholder='Contact Phone Number'
          type='number'
          max={14}
          value={formik.values.phoneNumber?.trimStart()}
          onChange={({ target: { value } }) => {
            if (value.length < 15) formik.setFieldValue("phoneNumber", value);
          }}
        />
        <input
          className='form-control'
          placeholder='Your Position e.g Buyer'
          value={formik.values.contactPosition?.trimStart()}
          onChange={({ target: { value } }) =>
            formik.setFieldValue("contactPosition", value)
          }
        />
        <button
          disabled={
            loading ||
            formik.values.firstName.length < 2 ||
            formik.values.lastName.length < 2 ||
            formik.values.email?.length < 3 ||
            formik.values.phoneNumber?.length < 3 ||
            formik.values.contactPosition?.length < 3
          }
          onClick={formik.handleSubmit}
        >
          {loading ? (
            <div class='spinner-border text-success app-spinner' role='status'>
              <span class='sr-only'></span>
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </div>
  );
}

function ConfirmEmail({
  sendUserEmailOTP,
  verifyUserToken,
  loading,
  seconds,
  setRegistrationSteps,
  token,
  resendingToken,
  setToken,
}) {
  return (
    <div className='form-section'>
      <div className='form-icon _logo'>
        <TDIcon />
      </div>
      <div className='form-itself'>
        <h3>Confirm signup</h3>
        <h5>Enter the verification code we sent to your email address</h5>
        <div className='v-input__'>
          <input
            placeholder='Enter verification code'
            className='form-control'
            value={token}
            disabled={resendingToken}
            maxLength={6}
            onChange={({ target: { value } }) => setToken(value)}
          />
          <span
            onClick={() => {
              if (seconds) return;
              setToken("");
              return sendUserEmailOTP({ isResending: true });
            }}
          >
            {resendingToken ? (
              <div
                class='spinner-border text-success app-spinner input-spinner'
                role='status'
              >
                <span class='sr-only'></span>
              </div>
            ) : !seconds ? (
              "Resend"
            ) : (
              `Resend in ${seconds}s`
            )}
          </span>
        </div>
        <button
          disabled={token.length < 6 || loading}
          onClick={verifyUserToken}
        >
          {loading ? (
            <div class='spinner-border text-success app-spinner' role='status'>
              <span class='sr-only'></span>
            </div>
          ) : (
            "Continue"
          )}
        </button>
        <h6>
          Still haven’t received a code? Check your spam folder, or{" "}
          <span
            onClick={() => {
              setToken("");
              setRegistrationSteps(2);
            }}
          >
            try a different email.
          </span>
        </h6>
      </div>
    </div>
  );
}

function OutletRegistration({
  formik,
  enterAddressManually,
  setShowAddressSelectContainer,
  loadingAddress,
  addresses,
  addressContainers,
  handleFetchAddressFromContainer,
  step,
  handleRetrieveAddress,
  showAddressSelectContainer,
  setAddressManualInput,
  storeTypes,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [countryAddress, setCountryAddress] = useState(""); // used for addresses of country that are not GB
  console.log("valiessss", formik.values);
  const handleContinue = () => {
    setLoading(false);
    formik.handleSubmit();
  };

  useEffect(() => {
    if (!formik?.values?.countryCode) return;
    formik.setFieldValue("outletTypeId", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.countryCode]);

  useEffect(() => {
    const googleScript = document.createElement("script");
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_ADDRESS_MAP_KEY}&libraries=places`;
    googleScript.async = true;
    googleScript.defer = true;

    googleScript.onload = () => {
      // Initialize Autocomplete after Google Maps script is loaded
      if (inputRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["geocode"],
          }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const { address_components } = place;
          formik.setFieldValue("latitude", place.geometry?.location.lat());
          formik.setFieldValue("longitude", place.geometry?.location.lng());
          formik.setFieldValue("storeAddress", place?.formatted_address || "");
          setCountryAddress(place?.formatted_address || "");
          const { long_name: country, short_name: countryCode } =
            address_components.find((el) => el.types.includes("country"));
          formik.setFieldValue("country", country || "");
          formik.setFieldValue("countryCode", countryCode || "");
          const postalCode = address_components.find((el) =>
            el.types.includes("postal_code")
          );
          formik.setFieldValue("postcode", postalCode?.long_name || "");
        });
      }
    };

    document.head.appendChild(googleScript);
    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(googleScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryAddress]); // Empty dependency array ensures that this useEffect runs only once on mount

  return (
    <div className='form-section'>
      <div className='form-icon'>
        <Kiosk />
      </div>
      <div className='form-itself'>
        {/* <h3>Welcome {formik.values.firstName}!</h3> */}
        <h3>Outlet Registration</h3>
        <h5>Fill in the organization details</h5>
        <input
          className='form-control'
          required
          placeholder='Organization Name'
          value={formik.values.outletBusinessName}
          onChange={({ target: { value } }) =>
            formik.setFieldValue("outletBusinessName", value.trimStart())
          }
        />
        <input
          className='form-control'
          placeholder='Organization phone number'
          type='number'
          value={formik.values.outletPhoneNumber.trimStart()}
          onChange={({ target: { value } }) => {
            if (value.length < 15)
              formik.setFieldValue("outletPhoneNumber", value);
          }}
        />
        <input
          className='form-control'
          placeholder='Organization email address'
          value={formik.values.outletEmail.trim()}
          onChange={({ target: { value } }) =>
            formik.setFieldValue("outletEmail", value)
          }
        />
        <div className='address-container'>
          {/* {!enterAddressManually && (
            <>
              <div className='v-input__'>
                <input
                  placeholder='Outlet address'
                  className='form-control'
                  value={formik.values.storeAddress}
                  onChange={({ target: { value } }) => {
                    setShowAddressSelectContainer(true);
                    formik.setFieldValue("storeAddress", value);
                  }}
                />
                <span
                  onClick={() => {
                    if (loadingAddress) return;
                    setAddressManualInput(!enterAddressManually);
                    formik.setFieldValue("storeAddress", "");
                  }}
                >
                  {formik.values.storeAddress &&
                  !loadingAddress ? null : loadingAddress ? (
                    <div
                      class='spinner-border text-success app-spinner input-spinner'
                      role='status'
                    >
                      <span class='sr-only'></span>
                    </div>
                  ) : (
                    "Not a UK user"
                  )}
                </span>
              </div>
              <AddressListContainer
                addressContainers={addressContainers}
                handleFetchAddressFromContainer={
                  handleFetchAddressFromContainer
                }
                addresses={addresses}
                handleRetrieveAddress={handleRetrieveAddress}
                step={step}
                showAddressSelectContainer={showAddressSelectContainer}
              />
            </>
          )} */}
          {/* {enterAddressManually ? (
            <p
              className='_prompt-text'
              onClick={() => {
                setAddressManualInput(!enterAddressManually);
                formik.setFieldValue("storeAddress", "");
              }}
            >
              Search Your Address
            </p>
          ) : null} */}
          {/* {enterAddressManually && ( */}
          <input
            className='form-control'
            placeholder='Organization Address'
            ref={inputRef}
            minLength={3}
            type='text'
            onChange={({ target: { value } }) => {
              if (value.length < 3) formik.setFieldValue("countryCode", "");
              setCountryAddress(value?.trimStart());
              formik.setFieldValue("storeAddress", value?.trimStart());
            }}
            value={countryAddress || formik.values.storeAddress}
          />
          {/* )} */}
        </div>
        <select
          className='form-select'
          disabled={!storeTypes.length}
          placeholder='Type of business'
          value={formik.values.outletTypeId || null}
          onChange={({ target: { value } }) =>
            formik.setFieldValue("outletTypeId", value)
          }
        >
          <option disabled selected>
            -- Business Type --
          </option>
          {storeTypes.map((el, key) => (
            <option key={key} value={el._id}>
              {el.name}
            </option>
          ))}
        </select>
        <button
          disabled={
            formik.values.outletBusinessName?.length < 3 ||
            (formik?.values?.storeAddress || "")?.trim()?.length < 3 ||
            formik.values.outletEmail?.length < 3 ||
            formik.values.outletPhoneNumber?.length < 3 ||
            formik.values.outletTypeId?.length < 3 ||
            loading
          }
          onClick={handleContinue}
        >
          {loading ? <Loader /> : "Continue"}
        </button>
        <h6>
          Already have an account?{" "}
          <span onClick={() => navigate("/login")}>Log in</span>
        </h6>
      </div>
    </div>
  );
}

export function LegalCompliance({ formik, nextStep, loading }) {
  return (
    <div className='form-section'>
      <div className='form-icon'>
        <LegalComplianceIcon />
      </div>
      <div className='form-itself'>
        <h3>Legal and Compliance</h3>
        <h5>Fill in your organization's bank details</h5>
        <div className='input-flex'>
          <div className=''>
            <CustomSelect
              disabled={!businessStatuses.length}
              items={businessStatuses.map((el) => ({
                title: el,
                value: el,
              }))}
              placeholder='Business Status'
              action={({ value }) =>
                formik.setFieldValue("businessStatus", value)
              }
              valueToDisplay={businessStatuses.find(
                (el) => el === formik.values.businessStatus
              )}
            />
          </div>
        </div>
        <input
          className='form-control'
          placeholder='Business Registration Number'
          value={formik.values.businessRegistrationNumber?.trim()}
          onChange={({ target: { value } }) =>
            formik.setFieldValue("businessRegistrationNumber", value)
          }
        />
        <div className='input-flex'>
          <div className=''>
            <CustomSelect
              disabled={!COUNTRIES.length}
              items={COUNTRIES.map((el) => ({
                title: el.name,
                value: el.code,
              }))}
              placeholder='Select country of incorporation'
              action={({ value }) => {
                const { name, code } = COUNTRIES.find(
                  (el) => el.code === value
                );
                formik.setFieldValue("countryOfIncorporation", name);
                formik.setFieldValue("countryCodeOfIncorporation", code);
              }}
              valueToDisplay={
                COUNTRIES.find(
                  (el) => el.name === formik.values.countryOfIncorporation
                )?.name
              }
            />
          </div>
        </div>
        <button
          disabled={
            formik?.values?.businessRegistrationNumber?.length < 4 ||
            !formik.values.businessStatus ||
            loading
          }
          onClick={nextStep}
        >
          {loading ? <Loader /> : "Save Data"}
        </button>
        {/* <button
          className='secondary'
          onClick={() => {
            formik.setFieldValue("businessRegistrationNumber", "");
            formik.setFieldValue("businessStatus", "");
            nextStep();
          }}
        >
          Skip
        </button> */}
      </div>
    </div>
  );
}

export function BankingInformation({ formik, nextStep, loading, banks = [] }) {
  return (
    <div className='form-section'>
      <div className='form-icon'>
        <CreditNoteIcon />
      </div>
      <div className='form-itself'>
        <h3>Banking Information</h3>
        <h5>Fill in your organization's bank details</h5>
        <div className='input-flex'>
          <div className=''>
            <CustomSelect
              disabled={!banks.length}
              items={banks.map((el) => ({
                title: el.name,
                value: el.name,
              }))}
              placeholder='Select Bank'
              action={({ value }) =>
                formik.setFieldValue("businessAccountName", value)
              }
              valueToDisplay={
                banks.find(
                  (el) => el.name === formik.values.businessAccountName
                )?.name
              }
            />
          </div>
        </div>
        <input
          className='form-control'
          placeholder='Business account number'
          value={formik?.values?.businessAccountNumber?.trim()}
          type='number'
          max={10}
          maxLength={10}
          onChange={({ target: { value } }) => {
            if (value.length < 11)
              formik.setFieldValue("businessAccountNumber", value);
          }}
        />
        <input
          className='form-control'
          placeholder='Account Signatory BVN'
          value={formik?.values?.businessAccountSignatoryBVN?.trim()}
          type='number'
          max={12}
          maxLength={12}
          onChange={({ target: { value } }) => {
            if (value.length < 12)
              formik.setFieldValue("businessAccountSignatoryBVN", value);
          }}
        />
        <button
          disabled={
            !formik?.values?.businessAccountName?.length ||
            !formik.values.businessAccountNumber ||
            formik?.values?.businessAccountNumber?.length < 10 ||
            !formik.values.businessAccountSignatoryBVN ||
            formik?.values?.businessAccountSignatoryBVN?.length < 11 ||
            loading
          }
          onClick={nextStep}
        >
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </div>
  );
}

export function DocumentsUpload({ formik, loading, nextStep }) {
  const handleDocumentUpload = (uploadInputId) => {
    document.getElementById(uploadInputId).click();
  };

  const handleFileChange = (event, documentName) => {
    const uploadedFile = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedTypes.includes(uploadedFile.type))
      return renderErrorToast(
        "Upload error",
        "The document uploaded is not one of the accepted formats",
        "upload-err-format"
      );
    const maxSizeInMB = 1; // Maximum allowed size in MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (uploadedFile && uploadedFile.size > maxSizeInBytes)
      return renderErrorToast(
        "Upload error",
        "The document uploaded exceeds the maximum allowed size",
        "upload-err-size"
      );
    // renderErrorToast(
    //   "Starting Upload",
    //   `Your document is being uploaded`,
    //   "upload-inprogress-doc"
    // );
    const reader = new FileReader();
    reader.onloadend = function () {
      const image = {
        type: documentName,
        file64: reader.result,
        file: uploadedFile.name,
      };
      const images = formik.values.images || [];
      const fileIndexInImages = images.findIndex(
        (image) => image.type === documentName
      );
      if (fileIndexInImages < 0) images.push(image);
      else images[fileIndexInImages] = image;
      formik.setFieldValue("images", images);
      renderErrorToast(
        "Upload complete",
        `Your ${documentName} has been uploaded`,
        "upload-done-doc"
      );
    };
    reader.readAsDataURL(uploadedFile);
  };

  const handleDeleteFile = (type) => {
    formik.setFieldValue("images", [
      ...formik.values.images.filter((image) => image.type !== type),
    ]);
  };

  const nationalIdentityType = "National Identity Card";
  const bnCertificateType = "bnCertificate";
  const partnersParticularsType = "partnerParticulars";

  const bnCertificateUploaded =
    (formik.values?.images || []).find((el) => el.type === bnCertificateType) ||
    null;
  const nationalIDUploaded =
    (formik.values?.images || []).find(
      (el) => el.type === nationalIdentityType
    ) || null;
  const partnersParticularsUploaded =
    (formik.values?.images || []).find(
      (el) => el.type === partnersParticularsType
    ) || null;

  function UploadImageView({ fileId, type, fileName }) {
    return (
      <>
        <input
          type='file'
          id={fileId}
          onChange={(e) => handleFileChange(e, type)}
          accept='.pdf,.png,.jpg'
        />
        <div
          className='input-container'
          onClick={() => handleDocumentUpload(fileId)}
        >
          <DocumentPlusIcon />
          <p>{fileName}</p>
        </div>
      </>
    );
  }

  function UploadedImageView({ fileId, uploadedDocument, type }) {
    return (
      <div className='uploaded-view'>
        <input
          type='file'
          id={fileId}
          onChange={(e) => handleFileChange(e, type)}
        />
        <div
          className='input-container'
          onClick={() => handleDocumentUpload(fileId)}
        >
          <UploadedDocumentPlusIcon />
          <p>
            <span>
              {uploadedDocument.file.substring(
                0,
                uploadedDocument.file.length - 4
              )}
            </span>
            {uploadedDocument.file.slice(-4)}
          </p>
        </div>
        <div className='edit-actions'>
          <span role='button' onClick={() => handleDocumentUpload(fileId)}>
            Upload New
          </span>
          <span role='button' onClick={() => handleDeleteFile(type)}>
            Delete
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className='form-section'>
      <div className='form-icon'>
        <UploadIcon />
      </div>
      <div className='form-itself'>
        <h3>Upload documents</h3>
        <h5>Upload your registration documents</h5>
        <div className='file-upload-container'>
          {nationalIDUploaded ? (
            <UploadedImageView
              fileId={"upload-national-id"}
              uploadedDocument={nationalIDUploaded}
              type={nationalIdentityType}
            />
          ) : (
            <UploadImageView
              fileId={"upload-national-id"}
              type={nationalIdentityType}
              fileName="National ID e.g driver's license, passport"
            />
          )}
        </div>
        <div className='file-upload-container'>
          {bnCertificateUploaded ? (
            <UploadedImageView
              fileId={"upload-bn-doc"}
              uploadedDocument={bnCertificateUploaded}
              type={bnCertificateType}
            />
          ) : (
            <UploadImageView
              fileId={"upload-bn-doc"}
              type={bnCertificateType}
              fileName='Cerificate of Incorporation (BN)'
            />
          )}
        </div>
        <div className='file-upload-container'>
          {partnersParticularsUploaded ? (
            <UploadedImageView
              fileId={"upload-pop-doc"}
              uploadedDocument={partnersParticularsUploaded}
              type={partnersParticularsType}
            />
          ) : (
            <UploadImageView
              fileId={"upload-pop-doc"}
              type={partnersParticularsType}
              fileName='Particulars of partners'
            />
          )}
        </div>
        <h4 className='upload-instruction'>
          Only upload .pdf, .jpg or .png files, maximum file size is 1MB
        </h4>
        <button
          disabled={!(formik.values?.images || {}).length || loading}
          onClick={nextStep}
        >
          {loading ? <Loader /> : "Submit"}
        </button>
        {/* <button
          className='secondary'
          disabled={loading || skipAndSubmitLoading}
          onClick={() => {
            setSkipAndSubmitLoading(true);
            handleCreateOutlet();
          }}
        >
          {skipAndSubmitLoading ? <Loader /> : "Skip and Submit"}
        </button> */}
      </div>
    </div>
  );
}

// function AddressListContainer({
//   addressContainers = [],
//   handleFetchAddressFromContainer,
//   handleRetrieveAddress,
//   addresses = [],
//   step = 1,
//   showAddressSelectContainer = false,
// }) {
//   const RenderAddressContainerList = () => {
//     return addressContainers.map((el) => (
//       <p className='' onClick={() => handleFetchAddressFromContainer(el)}>
//         <h6>{el.Text}</h6> <span>{el.Description}</span>
//       </p>
//     ));
//   };
//   const RenderAddressList = () => {
//     return addresses.map((el) => (
//       <p className='' onClick={() => handleRetrieveAddress(el)}>
//         {el.Text} <span>{el.Description}</span>
//       </p>
//     ));
//   };

//   return showAddressSelectContainer &&
//     (addresses.length || addressContainers.length) ? (
//     <div className='address-list-container'>
//       {addressContainers.length ? (
//         <RenderAddressContainerList />
//       ) : addresses.length ? (
//         <RenderAddressList />
//       ) : null}
//     </div>
//   ) : null;
// }
