export function PayoutIcon() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.04199 12.5418C9.04199 11.2532 10.0867 10.2085 11.3753 10.2085H16.6253C17.914 10.2085 18.9587 11.2532 18.9587 12.5418V20.1252C18.9587 21.4138 17.914 22.4585 16.6253 22.4585H11.3753C10.0867 22.4585 9.04199 21.4138 9.04199 20.1252V12.5418Z'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.4587 11.9582V7.87484C22.4587 6.58617 21.414 5.5415 20.1253 5.5415H7.87533C6.58666 5.5415 5.54199 6.58617 5.54199 7.87484V11.9582'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.4587 16.3333C15.4587 17.1387 14.8057 17.7917 14.0003 17.7917C13.1949 17.7917 12.542 17.1387 12.542 16.3333C12.542 15.5279 13.1949 14.875 14.0003 14.875C14.8057 14.875 15.4587 15.5279 15.4587 16.3333Z'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
