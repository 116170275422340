import { useEffect, useState } from "react";

const useTimer = () => {
  const SECONDS_TO_COUNT = 60;
  const [seconds, setSeconds] = useState(SECONDS_TO_COUNT);
  const [startCountdown, setStartCountdown] = useState(false);

  useEffect(() => {
    if (!startCountdown) return;
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (!seconds) setStartCountdown(false);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [seconds, startCountdown]);

  useEffect(() => {
    if (!startCountdown) return;
    return setSeconds(SECONDS_TO_COUNT);
  }, [startCountdown]);

  return [seconds, setStartCountdown];
};

export default useTimer;
