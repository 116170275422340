export function EditSvg() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 18.2498L9.17178 18.9799C9.30754 18.948 9.43171 18.8788 9.53033 18.7802L9 18.2498ZM4.75 19.2498L4.01994 19.0781C3.96045 19.3309 4.03601 19.5965 4.21967 19.7802C4.40333 19.9638 4.66895 20.0394 4.92178 19.9799L4.75 19.2498ZM5.75 14.9998L5.21967 14.4695C5.12105 14.5681 5.05188 14.6923 5.01994 14.8281L5.75 14.9998ZM18.2929 8.95695L18.8232 9.48728H18.8232L18.2929 8.95695ZM18.2929 7.54274L18.8232 7.01241L18.8232 7.01241L18.2929 7.54274ZM16.4571 5.70696L16.9874 5.17663V5.17663L16.4571 5.70696ZM15.0429 5.70696L15.5732 6.23729L15.5732 6.23729L15.0429 5.70696ZM8.82822 17.5198L4.57822 18.5198L4.92178 19.9799L9.17178 18.9799L8.82822 17.5198ZM5.48006 19.4216L6.48006 15.1716L5.01994 14.8281L4.01994 19.0781L5.48006 19.4216ZM9.53033 18.7802L18.8232 9.48728L17.7626 8.42662L8.46967 17.7195L9.53033 18.7802ZM18.8232 7.01241L16.9874 5.17663L15.9268 6.23729L17.7626 8.07307L18.8232 7.01241ZM14.5126 5.17663L5.21967 14.4695L6.28033 15.5302L15.5732 6.23729L14.5126 5.17663ZM16.9874 5.17663C16.304 4.49321 15.196 4.49321 14.5126 5.17663L15.5732 6.23729C15.6709 6.13966 15.8291 6.13965 15.9268 6.23729L16.9874 5.17663ZM18.8232 9.48728C19.5066 8.80387 19.5066 7.69583 18.8232 7.01241L17.7626 8.07307C17.8602 8.1707 17.8602 8.32899 17.7626 8.42663L18.8232 9.48728Z'
        fill='#081F24'
      />
      <path
        d='M19.25 20C19.6642 20 20 19.6642 20 19.25C20 18.8358 19.6642 18.5 19.25 18.5V20ZM13.75 18.5C13.3358 18.5 13 18.8358 13 19.25C13 19.6642 13.3358 20 13.75 20V18.5ZM19.25 18.5H13.75V20H19.25V18.5Z'
        fill='#081F24'
      />
    </svg>
  );
}
