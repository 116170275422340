export function BuildingStore() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.87527 22.4587H20.1253C21.4139 22.4587 22.4586 21.414 22.4586 20.1253V9.54713C22.4586 9.21404 22.3873 8.88482 22.2495 8.58159L21.622 7.20117C21.2434 6.36818 20.4128 5.83337 19.4978 5.83337H8.50273C7.58773 5.83337 6.75717 6.36818 6.37854 7.20117L5.75108 8.58159C5.61324 8.88482 5.54193 9.21404 5.54193 9.54713V20.1253C5.54193 21.414 6.5866 22.4587 7.87527 22.4587Z'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.0834 9.04163C11.0834 10.4914 9.91669 11.9583 8.16669 11.9583C6.41669 11.9583 5.54169 10.4914 5.54169 9.04163'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.4584 9.04163C22.4584 10.4914 21.5834 11.9583 19.8334 11.9583C18.0834 11.9583 16.9167 10.4914 16.9167 9.04163'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.9166 9.04163C16.9166 10.4914 15.75 11.9583 14 11.9583C12.25 11.9583 11.0833 10.4914 11.0833 9.04163'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.3746 18.3741C11.3746 17.0854 12.4192 16.0408 13.7079 16.0408H14.2912C15.5799 16.0408 16.6246 17.0854 16.6246 18.3741V22.4574H11.3746V18.3741Z'
        stroke='#FF8D06'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
