import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Register } from "./pages/register";
import { Login } from "./pages/login";
import { Console } from "./pages/console";
import { Layout } from "./components/layout";
import { Onboarding } from "./pages/onboarding";
import { Orders } from "./pages/orders";
import { CreateOrders } from "./pages/create-orders";
import { useEffect, useState } from "react";
import { MobileView } from "./components/mobile-view";
import { isUserLoggedIn } from "./utils/functions";
import { Invoices } from "./pages/invoices";
import { AccountStatement } from "./pages/account-statement";
import refreshOutlet from "./utils/refreshOutlet";
import { OrderView } from "./components/order-view";
import { Catalog } from "./pages/catalog";
import { ExportCustomerCreateOrder } from "./pages/export-create-orders";
import { NewRegistration } from "./pages/register-new";
import { ConfirmOTP } from "./pages/confirm-otp";
import { NotFoundView } from "./pages/not-found";
import { Shipments } from "./pages/shipments";
import { SalesOrders } from "./pages/sales.orders";
import { SalesInvoices } from "./pages/sales.invoices";
import {
  hasPurchaseOrdersPermission,
  hasSalesOrdersPermission,
} from "./utils/constants";
import { PayOuts } from "./pages/payouts";

const CreateOrderComponent = () => {
  const [isExportOutlet] = useState(
    JSON.parse(localStorage.getItem("IS_EXPORT_OUTLET"))
  );
  return isExportOutlet ? <ExportCustomerCreateOrder /> : <CreateOrders />;
};

function Router() {
  const [outlet, setOutlet] = useState(
    JSON.parse(localStorage.getItem("userData"))?.retailOutlets?.[0] ?? null
  );
  const [isMobile] = useState(window.innerWidth <= 1024);
  const { pathname } = window.location;
  if (pathname === "/") window.location.replace("/console");
  const canViewSalesOrders = hasSalesOrdersPermission(outlet);
  const canViewPurchaseOrders = hasPurchaseOrdersPermission(outlet);
  const authRoutes = ["/login", "/register", "/confirm"];
  if (!isUserLoggedIn() && !authRoutes.includes(pathname)) {
    console.log("entered hereee", window.location.href);
    localStorage.setItem("auth_deviated_route", window.location.href);
    window.location.replace("/login");
  }
  if (isUserLoggedIn() && authRoutes.includes(pathname))
    window.location.replace("/console");

  useEffect(() => {
    const fetchOutlet = setInterval(async () => {
      if (!JSON.parse(localStorage.getItem("userData"))) return;
      const outlet = JSON.parse(localStorage.getItem("userData"))
        ?.retailOutlets?.[0];
      if (outlet) {
        setOutlet(outlet);
        return clearTimeout(fetchOutlet);
      }
    }, 1000);
    return () => clearTimeout(fetchOutlet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetOutlet = async () => {
    if (!isUserLoggedIn()) return;
    refreshOutlet();
  };

  useEffect(() => {
    handleGetOutlet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile ? (
    <MobileView />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path='/register' element={<NewRegistration />} />
        {/* <Route path='/register' element={<Register />} /> */}
        <Route path='/login' element={<Login />} />
        <Route path='/confirm' element={<ConfirmOTP />} />
        <Route path='/' element={<Layout />}>
          <Route path='/console' element={<Console />} />
          <Route path='/reports' element={<Console />} />
          {canViewSalesOrders ? (
            <>
              <Route path='/sales-invoices' element={<SalesInvoices />} />
              <Route path='/sales-orders' element={<SalesOrders />} />
            </>
          ) : null}
          {canViewPurchaseOrders ? (
            <>
              <Route path='/orders' element={<Orders />} />
              <Route path='/invoices' element={<Invoices />} />
            </>
          ) : null}
          <Route path='/shipments' element={<Shipments />} />
          <Route path='/payouts' element={<PayOuts />} />
          <Route path='/catalog' element={<Catalog />} />
          <Route path='/suppliers' element={<Console />} />
          <Route path='/account-statement' element={<AccountStatement />} />
        </Route>
        {canViewSalesOrders ? (
          <>
            <Route
              path='/sales-order/:orderId'
              element={<OrderView isSalesOrder={true} />}
            />
            <Route
              path='/create-sales-order'
              element={<CreateOrders creatingSalesOrder={true} />}
            />
          </>
        ) : null}
        {canViewPurchaseOrders ? (
          <Route
            path='/orders/:orderId/?action=:action?'
            element={<OrderView />}
          />
        ) : null}
        <Route path='/onboarding' element={<Onboarding />} />
        <Route path='/create-order' element={<CreateOrderComponent />} />
        <Route path='*' element={<NotFoundView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
