export function UserCircleIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0413 10C16.0413 13.3368 13.3364 16.0417 9.99967 16.0417C6.66295 16.0417 3.95801 13.3368 3.95801 10C3.95801 6.66332 6.66295 3.95837 9.99967 3.95837C13.3364 3.95837 16.0413 6.66332 16.0413 10Z'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d='M11.875 8.33337C11.875 9.36891 11.0355 10.2084 10 10.2084C8.96447 10.2084 8.125 9.36891 8.125 8.33337C8.125 7.29784 8.96447 6.45837 10 6.45837C11.0355 6.45837 11.875 7.29784 11.875 8.33337Z'
          stroke='#081F24'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <path
        d='M5.99805 14.1666C6.95344 13.0208 8.39165 12.2916 10.0002 12.2916C11.6087 12.2916 13.0469 13.0208 14.0023 14.1666'
        stroke='#081F24'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
