export function Checkbox({ isChecked = false }) {
  return !isChecked ? (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.45801 10.1428C4.45801 6.80608 7.16295 4.10114 10.4997 4.10114C13.8364 4.10114 16.5413 6.80608 16.5413 10.1428C16.5413 13.4795 13.8364 16.1845 10.4997 16.1845C7.16295 16.1845 4.45801 13.4795 4.45801 10.1428Z'
        fill='#E7E7E7'
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d='M8.625 10.7678L8.98644 11.5381C9.27288 12.1486 10.128 12.1838 10.4637 11.5989L12.375 8.26782'
          stroke='#E7E7E7'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.45801 10.1428C4.45801 6.80608 7.16295 4.10114 10.4997 4.10114C13.8364 4.10114 16.5413 6.80608 16.5413 10.1428C16.5413 13.4795 13.8364 16.1845 10.4997 16.1845C7.16295 16.1845 4.45801 13.4795 4.45801 10.1428Z'
        fill='#081F24'
      />
      <path
        d='M8.625 10.7678L8.98644 11.5381C9.27288 12.1486 10.128 12.1838 10.4637 11.5989L12.375 8.26782'
        stroke='white'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
