import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthedHeader } from "./auth-header";
import { BuildingStore } from "../assets/building-store";
import { Orders, SalesOrders } from "../assets/orders";
import { InvoiceSvg, SalesInvoiceSvg } from "../assets/invoice";
import { ShoppingCart } from "../assets/shopping-cart";
import { AccountStatementSvg } from "../assets/account-statement-svg";
import { LogoutSvg } from "../assets/logout";
import { LSUserData, scrollBackToTop, userLogout } from "../utils/functions";
import { useEffect, useState } from "react";
import {
  hasPurchaseOrdersPermission,
  hasSalesOrdersPermission,
  isAnExportOutlet,
  isASalesOrderCustomer,
  LOCAL_SELECTED_CURRENCY,
  SUPPORTED_CURRENCIES,
} from "../utils/constants";
import { ShipmentMenuIcon } from "../assets/shipment.menu.icon";
import { PayoutIcon } from "../assets/payout.icon";

export function Layout() {
  const { pathname } = useLocation();
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem(LOCAL_SELECTED_CURRENCY) ||
      SUPPORTED_CURRENCIES[0].title // USD
  );

  useEffect(() => {
    scrollBackToTop();
  }, [pathname]);

  return (
    <div
      className='container-fluid tdp_layout'
      onClick={() => setShowCurrencyDropdown(false)}
    >
      <div className='row'>
        <div className='col-2 side-nav-container'>
          <div className='sticky-col-2'>
            <SideBar />
          </div>
        </div>
        <div className='col-10' style={{ background: "#fcfcfc" }}>
          <div className='view-box'>
            <AuthedHeader
              showCurrencyDropdown={showCurrencyDropdown}
              setShowCurrencyDropdown={setShowCurrencyDropdown}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
            />
            <div className='content-wrapper'>
              <Outlet context={{ selectedCurrency }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SideBar() {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const { retailOutlets: [retailOutlet] = [] } = LSUserData() || {};
  const canViewPurchaseOrders = hasPurchaseOrdersPermission(retailOutlet);
  const canViewSalesOrders = hasSalesOrdersPermission(retailOutlet);

  if (!LSUserData()) return window.location.replace("/login");

  return (
    <>
      <div className='outlet-handler'>
        <span>
          <img
            src='/images/icons/building.svg'
            alt='td-icon-svg'
            className='img-fluid'
          />
        </span>
        <p>{retailOutlet?.outletBusinessName || ""}</p>
      </div>
      <div className='tdp_side_nav'>
        <div
          className={`nav_menu ${pathname === "/console" && "active"}`}
          onClick={() => navigate("/console")}
        >
          <BuildingStore />
          <div>
            <h4>Home</h4>
            <h6>Console overview</h6>
          </div>
        </div>
        {canViewPurchaseOrders ? (
          <div
            className={`nav_menu ${pathname === "/orders" && "active"}`}
            onClick={() => navigate("/orders")}
          >
            <Orders />
            <div>
              <h4>Purchase Orders</h4>
              <h6>Create and track purchase orders</h6>
            </div>
          </div>
        ) : null}
        {canViewPurchaseOrders ? (
          <div
            className={`nav_menu ${pathname === "/invoices" && "active"}`}
            onClick={() => navigate("/invoices")}
          >
            <InvoiceSvg />
            <div>
              <h4>Purchase Invoices</h4>
              <h6>Review and pay invoices</h6>
            </div>
          </div>
        ) : null}
        {isAnExportOutlet(retailOutlet) ? (
          <div
            className={`nav_menu ${pathname === "/shipments" && "active"}`}
            onClick={() => navigate("/shipments")}
          >
            <ShipmentMenuIcon />
            <div>
              <h4>Shipments</h4>
              <h6>Track your shipments</h6>
            </div>
          </div>
        ) : null}
        <div
          className={`nav_menu ${pathname === "/payouts" && "active"}`}
          onClick={() => navigate("/payouts")}
        >
          <PayoutIcon />
          <div>
            <h4>Payouts</h4>
            <h6>See your payout history</h6>
          </div>
        </div>
        <div
          className={`nav_menu ${pathname === "/catalog" && "active"}`}
          onClick={() => navigate("/catalog")}
        >
          <ShoppingCart />
          <div>
            <h4>Catalog</h4>
            <h6>Browse available products</h6>
          </div>
        </div>
        {isASalesOrderCustomer(retailOutlet) && canViewSalesOrders ? (
          <div
            className={`nav_menu ${pathname === "/sales-orders" && "active"}`}
            onClick={() => navigate("/sales-orders")}
          >
            <SalesOrders />
            <div>
              <h4>Sales Orders</h4>
              <h6>Create and track sales orders</h6>
            </div>
          </div>
        ) : null}
        {isASalesOrderCustomer(retailOutlet) && canViewSalesOrders ? (
          <div
            className={`nav_menu ${pathname === "/sales-invoices" && "active"}`}
            onClick={() => navigate("/sales-invoices")}
          >
            <SalesInvoiceSvg />
            <div>
              <h4>Sales Invoices</h4>
              <h6>Review sales invoices</h6>
            </div>
          </div>
        ) : null}
        {/*
      <div
        className={`nav_menu ${pathname === "/suppliers" && "active"}`}
        onClick={() => navigate("/suppliers")}
      >
        <Cabinet />
        <div>
          <h4>Suppliers</h4>
          <h6>Manage your suppliers</h6>
        </div>
      </div>
      <div
        className={`nav_menu ${pathname === "/reports" && "active"}`}
        onClick={() => navigate("/reports")}
      >
        <ReportSvg />
        <div>
          <h4>Reports</h4>
          <h6>Download reports</h6>
        </div>
      </div> */}
        <div
          className={`nav_menu ${
            pathname === "/account-statement" && "active"
          }`}
          onClick={() => navigate("/account-statement")}
        >
          <AccountStatementSvg />
          <div>
            <h4>Account Statement</h4>
            <h6>Track account activities</h6>
          </div>
        </div>
        <div className={`nav_menu logout`} onClick={userLogout}>
          <LogoutSvg />
          <div>
            <h4>Logout</h4>
          </div>
        </div>
      </div>
    </>
  );
}
