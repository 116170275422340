import { useState } from "react";
import { ChevronRight } from "../../assets/chevrons";
import { InfoSvg } from "../../assets/info";
import { LegalIcon } from "../../assets/legal.icon";
import { BuildingIcon } from "../../assets/register.building";
import { UserCircleIcon } from "../../assets/user.circle.icon";
import {
  errorHandler,
  LSUserData,
  renderErrorToast,
  userLogout,
} from "../../utils/functions";
import { DeleteSvg, DeleteSvg2 } from "../../assets/delete";
import { Checkbox } from "../../assets/checkbox";
import axios from "axios";
import { Loader } from "../loader";

export function AccountSettings() {
  const [viewType, setViewType] = useState("");
  return (
    <>
      <div
        className='offcanvas offcanvas-end tdp_phone_offcanvas tdp_review_order_offcanvas tdp_account_settings'
        tabindex='-1'
        id='offcanvasAccountSettings'
        aria-labelledby='offcanvasAccountSettingsLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvasAccountSettingsLabel'>
            <i
              className='bi bi-x-lg'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='close-account-setting-drawer'
            />
            <p>Your account</p>
          </h5>
        </div>
        <div className='offcanvas-body'>
          {!viewType ? (
            <div className='animate__animated animate__slideInLeft animate__faster menu-list'>
              <div className='menu' onClick={() => setViewType("profile")}>
                <UserCircleIcon />
                <div>
                  <h6>Your profile</h6>
                  <p>View your personal profile information</p>
                </div>
                <ChevronRight />
              </div>
              <div className='menu' onClick={() => setViewType("organization")}>
                <BuildingIcon />
                <div>
                  <h6>Organization Profile</h6>
                  <p>View your organization profile information</p>
                </div>
                <ChevronRight />
              </div>
              <div
                className='menu'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <LegalIcon />
                <div>
                  <h6>Legal</h6>
                  <p>View terms of use & privacy policy</p>
                </div>
                <span className='direction'>
                  <ChevronRight />
                </span>
                <ul class='dropdown-menu'>
                  <a
                    className='dropdown-item'
                    onClick={() =>
                      window.open("https://tradedepot.co/terms", "_blank")
                    }
                    href='https://tradedepot.co/terms'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <li>
                      <InfoSvg />
                      <p>Terms of use</p>
                    </li>
                  </a>
                  <a
                    class='dropdown-item'
                    href='https://tradedepot.co/privacy'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      window.open("https://tradedepot.co/privacy", "_blank")
                    }
                  >
                    <li>
                      <InfoSvg />
                      <p>Privacy Policy</p>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          ) : viewType === "profile" ? (
            <AccountDetails setViewType={setViewType} />
          ) : viewType === "organization" ? (
            <OrganizationDetails setViewType={setViewType} />
          ) : null}
        </div>
      </div>
      <DeleteAccountScreens />
    </>
  );
}

function AccountDetails({ setViewType }) {
  const { firstName, lastName, email, phoneNumber, retailOutlets } =
    LSUserData() || {};

  return (
    <div class='animate__animated animate__slideInRight animate__faster menu-list'>
      <div className='account-details-view'>
        <div className='title'>
          <h6 onClick={() => setViewType("")}>Settings</h6>
          <ChevronRight />
          <h6>Your profile</h6>
        </div>
        <h3>Profile Information</h3>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>First name</label>
            <input className='form-control' value={firstName} disabled />
          </div>
          <div className=''>
            <label for='lastName'>Last name</label>
            <input className='form-control' value={lastName} disabled />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Email address</label>
            <input value={email} className='form-control' disabled />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Phone Number</label>
            <input className='form-control' value={phoneNumber} disabled />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Outlet Type</label>
            <input
              className='form-control'
              value={retailOutlets?.[0]?.outletType}
              disabled
            />
          </div>
        </div>
        <div
          className='delete-section'
          data-bs-toggle='offcanvas'
          href='#offcanvasDeleteAccountSettings'
          role='button'
          aria-controls='offcanvasDeleteAccountSettings'
        >
          <button className='btn btn-primary'>
            <DeleteSvg2 />
            Delete account
          </button>
          <ChevronRight />
        </div>
      </div>
    </div>
  );
}

function OrganizationDetails({ setViewType }) {
  const { retailOutlets } = LSUserData() || {};
  const retailOutlet = retailOutlets?.[0] || {};

  return (
    <div class='animate__animated animate__slideInRight animate__faster menu-list'>
      <div className='account-details-view organization'>
        <div className='title'>
          <h6 onClick={() => setViewType("")}>Settings</h6>
          <ChevronRight />
          <h6>Organization profile</h6>
        </div>
        <h3>Organization Information</h3>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Organization name</label>
            <input
              className='form-control'
              value={retailOutlet?.outletBusinessName}
              disabled
            />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Business Location</label>
            <input
              value={`${retailOutlet?.city}, ${retailOutlet?.state}`}
              className='form-control'
              disabled
            />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Phone Number</label>
            <input
              className='form-control'
              value={retailOutlet?.contactPhone}
              disabled
            />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Email address</label>
            <input
              className='form-control'
              value={retailOutlets?.[0]?.email}
              disabled
            />
          </div>
        </div>
        <hr />
        <h3>Business status</h3>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Business Status</label>
            <input className='form-control' value={"Registered"} disabled />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Type of business</label>
            <input
              className='form-control'
              value={retailOutlet?.company?.kyb?.businessType || "-"}
              disabled
            />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Business registration number</label>
            <input
              className='form-control'
              value={retailOutlet?.company?.kyb?.rcNumber || "-"}
              disabled
            />
          </div>
        </div>

        <hr />
        <h3>Banking Information</h3>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Account name</label>
            <input
              className='form-control'
              value={retailOutlet?.outletBusinessName || "-"}
              disabled
            />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Account number</label>
            <input
              className='form-control'
              value={retailOutlet?.account?.accountNumber || "-"}
              disabled
            />
          </div>
        </div>
        <div className='c_form'>
          <div className=''>
            <label for='firstName'>Account signatory BVN</label>
            <input
              className='form-control'
              value={retailOutlet?.company?.kyb?.bvn || "-"}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function DeleteAccountScreens() {
  const [firstConfirmation, setFirstConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { apiKey } = LSUserData() || {};

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/retail/delete/account`,
        {},
        { headers: { "x-api-key": apiKey } }
      );
      userLogout();
    } catch (error) {
      setLoading(false);
      const {
        message = "There was an issue with deleting your account. Please contact support",
      } = errorHandler(error);
      renderErrorToast(
        "Something went wrong!",
        message,
        "err-delete-account-1"
      );
    }
  };
  return (
    <div
      className='offcanvas offcanvas-end tdp_phone_offcanvas tdp_review_order_offcanvas tdp_account_settings'
      tabindex='-1'
      id='offcanvasDeleteAccountSettings'
      aria-labelledby='offcanvasDeleteAccountSettingsLabel'
    >
      <div className='offcanvas-header'>
        <h5
          className='offcanvas-title'
          id='offcanvasDeleteAccountSettingsLabel'
        >
          <i
            className='bi bi-x-lg'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
            id='close-account-setting-drawer'
          />
          <p>Delete account</p>
        </h5>
      </div>
      {!firstConfirmation ? (
        // first confirmation delete screen
        <div className='offcanvas-body delete-screens'>
          <div className='delete-icon-wrapper'>
            <DeleteSvg />
          </div>
          <h3>Are you sure you want to delete your account?</h3>
          <p>
            Deleting your account will erase your data and you will no longer
            have access to TradeDepot [wholesale]
          </p>
          <button onClick={() => setFirstConfirmation(true)}>
            Yes, I want to delete my account
          </button>
          <button
            className='secondary'
            data-bs-toggle='offcanvas'
            href='#offcanvasAccountSettings'
            role='link'
            aria-controls='offcanvasAccountSettings'
          >
            Cancel
          </button>
        </div>
      ) : (
        // second confirmation delete screen
        <div className='offcanvas-body delete-screens'>
          <div className='delete-icon-wrapper'>
            <DeleteSvg />
          </div>
          <h5>
            Once your account is deleted, TradeDepot [Wholesale] won’t remember
            the information you might have shared including:
          </h5>
          <ul>
            <li>
              <Checkbox isChecked />
              Your name
            </li>
            <li>
              <Checkbox isChecked />
              Email address
            </li>
            <li>
              <Checkbox isChecked />
              Phone number
            </li>
          </ul>
          <button onClick={handleDeleteAccount} disabled={loading}>
            {loading ? <Loader /> : "Yes, I want to delete my account"}
          </button>
          <button
            className='secondary'
            onClick={() => setFirstConfirmation(false)}
          >
            Go back
          </button>
          <h6>
            For more information about how we handle your data, see our{" "}
            <a
              onClick={() =>
                window.open("https://tradedepot.co/privacy", "_blank")
              }
              href='https://tradedepot.co/privacy'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Statement
            </a>
          </h6>
        </div>
      )}
    </div>
  );
}
