export function Person2() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.875 12C22.875 14.6924 20.6924 16.875 18 16.875C15.3076 16.875 13.125 14.6924 13.125 12C13.125 9.30761 15.3076 7.125 18 7.125C20.6924 7.125 22.875 9.30761 22.875 12Z'
        stroke='#081F24'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.2713 28.875H25.7288C27.4417 28.875 28.7611 27.4022 27.9612 25.8876C26.7846 23.6596 24.1021 21 18.0001 21C11.8981 21 9.21558 23.6596 8.03894 25.8876C7.23903 27.4022 8.55846 28.875 10.2713 28.875Z'
        stroke='#081F24'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
