export function MessageIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.9585 6.45866C3.9585 5.53818 4.70469 4.79199 5.62516 4.79199H14.3752C15.2956 4.79199 16.0418 5.53818 16.0418 6.45866V13.542C16.0418 14.4625 15.2956 15.2087 14.3752 15.2087H5.62516C4.70469 15.2087 3.9585 14.4625 3.9585 13.542V6.45866Z'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5835 5.41699L10.0002 10.2087L15.4168 5.41699'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
