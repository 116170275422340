import { useEffect, useState } from "react";
import {
  errorHandler,
  formatMoney,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import { Orders as OrdersSvg } from "../assets/orders";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { relativeStatus } from "../utils/constants";
import moment from "moment";
import { Pagination } from "../components/pagination";
import { PayoutViewModal } from "../components/drawers/payout.view";

export function PayOuts() {
  const { apiKey } = LSUserData() || {};
  const [status, setStatus] = useState("all");
  const [payouts, setPayouts] = useState([]);
  const [pagination, setPagination] = useState({ totalCount: 0, perPage: 10 });
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const navigate = useNavigate();
  const hasFilter = true;

  useEffect(() => {
    if (!searchText && !searchText?.length) return;
    const runFunc = setTimeout(async () => {
      handleFetchPayouts();
    }, 2000);
    return () => clearTimeout(runFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (!searchText?.length) handleFetchPayouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, currentPage, searchText]);

  const handleFetchPayouts = async () => {
    try {
      setLoadingScreen(true);
      const filters = {
        limit: pagination?.perPage,
        batch: currentPage,
        order: "desc",
      };
      if (status !== "all") filters.status = status;
      if (searchText.length) filters.searchQuery = searchText.trim();
      const queryString = new URLSearchParams(filters).toString();
      const {
        data: {
          data: { data, bankAccount, total, page },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/partners/collections?${queryString}`,
        { headers: { "x-api-key": apiKey } }
      );
      setPagination({ ...pagination, totalCount: total });
      setCurrentPage(page);
      setPayouts([...data.map((el) => ({ ...el, bankAccount }))]);
      setLoadingScreen(false);
    } catch (error) {
      setLoadingScreen(false);
      const {
        message = "There was a problem fetching payouts. Please contact support",
      } = errorHandler(error);
      renderErrorToast("Something went wrong!", message, "payout-err-1");
    }
  };

  return (
    <div className='tdp_orders tdp_invoices tdp_payouts'>
      <div className='header page-header'>
        <h2>Payouts</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={status === "all" ? "active" : ""}
              onClick={() => {
                setStatus("all");
                scrollBackToTop();
              }}
            >
              All
            </button>
            <button
              className={status === "inprogress" ? "active" : ""}
              onClick={() => {
                setStatus("inprogress");
                scrollBackToTop();
              }}
            >
              In Progress
            </button>
            <button
              className={status === "success" ? "active" : ""}
              onClick={() => {
                setStatus("success");
                scrollBackToTop();
              }}
            >
              Completed
            </button>
          </div>
        </div>
      </div>
      <div className='body page-body list-body'>
        {
          <div
            className={`__input-search ${
              !loadingScreen && !payouts.length ? "empty" : ""
            }`}
          >
            <i className='bi bi-search'></i>
            <input
              className='form-control order-search'
              placeholder='Type to search by payout ID'
              onChange={({ target: { value } }) => {
                setSearchText(value);
                scrollBackToTop();
              }}
            />
          </div>
        }
        <div className='orders-list'>
          {loadingScreen ? (
            <div className='screen-loader-wrapper'>
              <Skeleton count={10} />
            </div>
          ) : !loadingScreen && !payouts.length ? (
            <div className='empty__list'>
              <OrdersSvg />
              <h3>
                {hasFilter && status !== "all"
                  ? `No ${status} payouts available`
                  : hasFilter
                  ? "No payouts matching your filter criteria"
                  : "No Payouts Yet!"}
              </h3>
              <p onClick={() => navigate("/create-order")}>
                Create a new order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : !loadingScreen ? (
            <div className='entities'>
              <table className='table customer-portal-table'>
                <thead>
                  <tr>
                    <th scope='col' colSpan={2}>
                      Payout ID
                    </th>
                    <th scope='col' className='' colSpan={1}>
                      Amount sent
                    </th>
                    <th scope='col' colSpan={1}>
                      Linked Invoices
                    </th>
                    <th scope='col'>Customer Name</th>
                    <th scope='col'>Receiving Bank</th>
                    <th scope='col' className='pd-left-30'>
                      Fees
                    </th>
                    <th scope='col'>Arrive by</th>
                    <th scope='col' colSpan={1}></th>
                  </tr>
                </thead>
                <tbody>
                  {payouts.map((payout, key) => (
                    <tr key={key}>
                      <td colSpan={2}>{payout?.reference ?? "-"}</td>
                      <td className='amount' colSpan={1}>
                        <p>
                          <span>
                            {payout?.currency?.symbol || "₦"}
                            {formatMoney(payout?.amount || 0)}
                          </span>
                          <span>{payout?.currency?.iso || "NGN"}</span>
                        </p>
                      </td>
                      <td colSpan={1}>
                        <>
                          {!payout?.invoices?.length ? (
                            "-"
                          ) : (
                            <span className='invoices'>
                              {[payout?.invoices?.[0], payout?.invoices?.[1]]
                                .filter((el) => !!el)
                                .map((el) => el.invoiceNumber)
                                .join(", ")}
                              {payout?.invoices?.length > 2
                                ? `, +${payout?.invoices?.length - 2}`
                                : null}
                            </span>
                          )}
                        </>
                      </td>
                      <td>{payout?.outletName}</td>
                      <td>
                        {payout?.bankAccount?.bank}{" "}
                        <span>
                          <i className='bi bi-dot'></i>{" "}
                          {payout?.bankAccount?.accountNumber?.slice(-4)}
                        </span>
                      </td>
                      <td className='amount' colSpan={1}>
                        <p>
                          <span>
                            {payout?.currency?.symbol || "₦"}
                            {formatMoney(payout?.serviceCharge)}
                          </span>
                          <span> {payout?.currency?.iso || "NGN"}</span>
                        </p>
                      </td>
                      <td className='createdAt'>
                        <span>{moment(payout.createdAt).format("Do MMM")}</span>
                        {status === "all" ? (
                          <span
                            className={`custom_status ${relativeStatus(
                              payout.status === "success"
                                ? "success"
                                : "pending"
                            )}`}
                          >
                            {payout.status === "success"
                              ? "Completed"
                              : "In Progress"}
                          </span>
                        ) : null}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedPayout(payout);
                        }}
                        colSpan={1}
                        className='actions-td view'
                        data-bs-toggle='offcanvas'
                        href='#payoutOffCanvasExample'
                        role='button'
                        aria-controls='payoutOffCanvasExample'
                      >
                        <span
                          role='button'
                          className={`action-row-button ${
                            false ? "disabled" : ""
                          }`}
                          onClick={() => setSelectedPayout(payout)}
                        >
                          View
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                dataCount={pagination.totalCount}
                perPage={pagination?.perPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                currentPage={currentPage}
              />
            </div>
          ) : null}
        </div>
      </div>
      <PayoutViewModal payout={selectedPayout} />
    </div>
  );
}
