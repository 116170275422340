export function Counter({
  disabled = false,
  cartProducts = [],
  product = {},
  handleAddProduct,
  handleRemoveProduct,
  handleInputProductQuantity,
}) {
  const item =
    cartProducts.find((el) => {
      if (product?.supplier) {
        return el._id === product?._id && el.supplier === product?.supplier;
      } else return el._id === product?._id;
    }) || product;

  return (
    <div className='custom_counter' onClick={(e) => e.stopPropagation()}>
      <div className='plus_minus_controller'>
        <button
          disabled={disabled}
          onClick={() => {
            if (disabled) return;
            handleRemoveProduct(item, item?.supplier);
          }}
        >
          <i className='bi bi-dash-lg'></i>
        </button>
        <input
          value={disabled ? "" : item?.count || 0}
          onChange={({ target: { value } }) => {
            if (disabled) return;
            handleInputProductQuantity(item, value, item?.supplier);
          }}
          disabled={disabled}
        />
        <button
          disabled={disabled}
          onClick={() => {
            if (disabled) return;
            handleAddProduct(item, item?.supplier);
          }}
        >
          <i className='bi bi-plus-lg'></i>
        </button>
      </div>
    </div>
  );
}
