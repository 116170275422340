export function LogoutSvg() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.375 10.2083L22.4583 14L18.375 17.7916'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.1667 14H12.5417'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.7917 5.54169H7.87508C6.58642 5.54169 5.54175 6.58636 5.54175 7.87502V20.125C5.54175 21.4137 6.58642 22.4584 7.87508 22.4584H17.7917'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
